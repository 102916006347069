import React, { useContext, useState } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  Button,
} from "react-bootstrap";
import { TertiaryBlueButton, TertiaryGreenButton } from "../Button/styles";

import ProfileMenu from "../ProfileMenu/ProfileMenu";
import { MonitorMainTabsEnum } from "../../domain/enum/MonitorMainTabsEnum";
import { MainHeaderStyles } from "./styles";
import { MainContext } from "../../context/MainContext";

export default function Header() {
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState<MonitorMainTabsEnum>(MonitorMainTabsEnum.MyClasses);
  function time() {
    var today = new Date();
    var timeAtual = today.getHours() + ":" + today.getMinutes();
    const withPmAm = today.toLocaleTimeString("pt-Br", {
      // en-US can be set to 'default' to use user's browser settings
      hour: "2-digit",
      minute: "2-digit",
    });

    return withPmAm;
  }

  const {
    setSelectedHeaderTab, 
    selectedHeaderTab, 
    userHasClasses,
    reloadData
  } = useContext(MainContext)

  return (
    <MainHeaderStyles>
      <Container fluid={true}>
        <Row>
          <Col md={"auto"} className="my-auto mx-3">
            <h1>
              Monitor de Aulas
            </h1>
          </Col>

          <Col md={"auto"} className="col-nav-bar ms-1">
            <Nav variant="pills" defaultActiveKey="/home">
              <Nav.Item className="mx-1" >
                <Nav.Link 
                  active={selectedHeaderTab === MonitorMainTabsEnum.MyClasses}
                  onClick={()=> setSelectedHeaderTab(MonitorMainTabsEnum.MyClasses)}
                  disabled={!userHasClasses}
                  >
                  <Row className="mt-3">
                    <Col md="auto" className="pe-0">
                      <i className="bi bi-person"/>
                    </Col>
                    <Col md="auto">
                      <span>
                        {" "}
                        Minhas Aulas
                      </span>
                    </Col>
                  </Row>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="mx-1">
                <Nav.Link 
                  active={selectedHeaderTab === MonitorMainTabsEnum.AllLessons}
                  onClick={()=> setSelectedHeaderTab(MonitorMainTabsEnum.AllLessons)}
                  >
                  <Row className="mt-3">
                    <Col md="auto" className="pe-0">
                      <i className="bi bi-people"/>
                    </Col>
                    <Col md="auto">
                      <span>
                        {" "}
                        Todas as Aulas
                      </span>
                    </Col>
                  </Row>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>

          <Col
            md={"auto"}
            className="my-auto ms-auto update-time"
          >
            <span>
              Dados atualizados às {time()}
            </span>
          </Col>
          <Col md={"auto"} className="my-auto mx-1">
            <TertiaryBlueButton className="pl-3" onClick={()=>reloadData()}>
              <Row>
                <Col md={"auto"}>
                  <i className="bi bi-arrow-repeat"/>
                </Col>
              </Row>
            </TertiaryBlueButton>
          </Col>
          <Col md={"auto"} className="my-auto ms-3 me-4">
            <TertiaryGreenButton onClick={() => setShow(!show)} className="pl-3"
              active={show}
            >
              <Row>
                <Col md={"auto"}>
                  <i className="bi bi-box-arrow-right"/>
                </Col>
              </Row>
            </TertiaryGreenButton>
          </Col>
        </Row>
      </Container>
      <ProfileMenu showProfileMenu={show} />

    </MainHeaderStyles>
  );
}


