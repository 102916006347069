import React, { useCallback, useContext, useEffect, useState } from "react";
import { Col, Row, Nav } from "react-bootstrap";
import CenarioVazio from "../../components/CenarioVazio/Cenario";
import { MainStyleNavBar } from "../../components/NavBar/styles";
import { MainContext } from "../../context/MainContext";
import ClassesThatHappenedLastWeekListComponent from "./ClassesThatHappenedLastWeek/ClassesThatHappenedLastWeekListComponent";
import ClassesThatHappenedThisWeekListComponent from "./ClassesThatHappenedThisWeek/ClassesThatHappenedThisWeekListComponent";

const PastClasses: React.FC = ({}) => {
  const {currentPage} = useContext(MainContext)
  const [activeTab, changeActiveTab] = useState(1);

  
  const {
    setIsLoadingList, 
    setCurrentPage
  } = useContext(MainContext)

  const handleChangeActiveTab = (tab:number)=>{
    setIsLoadingList(true)
    setCurrentPage(1)
    changeActiveTab(tab)
  }


  const CallBack = useCallback(() => {
    if (activeTab == 1) {
      return <ClassesThatHappenedThisWeekListComponent />;
    } else if (activeTab == 2) {
      return <ClassesThatHappenedLastWeekListComponent />;
    } else {
      return <CenarioVazio />;
    }
  }, [activeTab]);

  return (
      <Col>
        <Row>
          <Col>
            <MainStyleNavBar
              variant="pills"
              defaultActiveKey="/home"
              className="mb-0"
            >
              <Nav.Item className="mx-1">
                <Nav.Link
                  active={activeTab === 1}
                  onClick={() => handleChangeActiveTab(1)}
                  className="pb-2"
                >
                  Nesta semana
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="mx-1">
                <Nav.Link
                  active={activeTab === 2}
                  onClick={() => handleChangeActiveTab(2)}
                  className="pb-2"
                >
                  Semana passada
                </Nav.Link>
              </Nav.Item>
            </MainStyleNavBar>
          </Col>
        </Row>
        <Row className="content-row">
          <CallBack />
        </Row>
      </Col>
  );
};

export default PastClasses;
