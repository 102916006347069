import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
} from "react-bootstrap";
import { ManageCourseSideMenuStyles } from "./styles";
import styled from "styled-components";
import "./imagem.css";
import { AiOutlineClockCircle } from "react-icons/ai";
import { BsCalendar3, BsArrowRepeat } from "react-icons/bs";
import { MyClassesMenuOptionEnum } from "../../domain/enum/MyClassesMenuOptionEnum";
import { MainContext } from "../../context/MainContext";

const ControlPanelSideMenu = () => {

  const {
    selectedMyClassesMenuOption, changeMenuOptions 
  } = useContext(MainContext)

  return (
    <ManageCourseSideMenuStyles md={"auto"} className="pt-4">
          <ListGroup>
            <ListGroupItem className="px-0 py-0">
              <Button
                className={selectedMyClassesMenuOption == MyClassesMenuOptionEnum.Today ? "active" : ""}
                onClick={()=>changeMenuOptions(MyClassesMenuOptionEnum.Today)}
              >
                <Row>
                  <Col md="auto" className="mt-auto">
                    <AiOutlineClockCircle size={25} /> 
                  </Col>
                  <Col md="auto" className="mt-auto">
                    <span>Hoje</span>
                  </Col>
                </Row>
              </Button>
            </ListGroupItem>
            <ListGroupItem className="px-0 py-0">
              <Button
                className={selectedMyClassesMenuOption == MyClassesMenuOptionEnum.Scheduled? "active" : ""}
                onClick={()=>changeMenuOptions(MyClassesMenuOptionEnum.Scheduled)}
              >
                <Row>
                  <Col md="auto" className="mt-auto">
                    <BsCalendar3 size={25}/>
                  </Col>
                  <Col md="auto" className="mt-auto">
                    <span >
                      Programadas
                    </span>
                  </Col>
                </Row>
              </Button>
            </ListGroupItem>
            <ListGroupItem className="px-0 py-0">
              <Button
                className={selectedMyClassesMenuOption == MyClassesMenuOptionEnum.Past ? "active" : ""}
                onClick={()=>changeMenuOptions(MyClassesMenuOptionEnum.Past)}
              >
                <Row>
                  <Col md="auto" className="mt-auto">
                    <BsArrowRepeat size={25}/>  
                  </Col>
                  <Col md="auto" className="mt-auto">
                    <span>Passadas</span>
                  </Col>
                </Row>
              </Button>
            </ListGroupItem>
            <ListGroupItem className="px-0 py-0">
              <Button
                className={selectedMyClassesMenuOption == MyClassesMenuOptionEnum.Reports ? "active" : ""}
                onClick={()=>changeMenuOptions(MyClassesMenuOptionEnum.Reports)}
              >
                <Row>
                  <Col md="auto" className="mt-auto">
                    <i className="bi bi-bar-chart"/>  
                  </Col>
                  <Col md="auto" className="mt-auto">
                    <span>Relatórios</span>
                  </Col>
                </Row>
              </Button>
            </ListGroupItem>
          </ListGroup>
    </ManageCourseSideMenuStyles>
  );
};

export default ControlPanelSideMenu;

export const Image = styled.img`
  height: 24px;
  width: 24px;
`;
