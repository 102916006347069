import { ReactNode, useContext } from "react";
import { Button, Col, Row } from "react-bootstrap";
import SkeletonComponent from "../components/Skeleton/Skeleton";
import CenarioVazio from "../components/CenarioVazio/Cenario";
import { MainContext } from "../context/MainContext";
import { PaginationButtons } from "./PaginationButtons";
import { EmptySearchComponent } from "./EmptySearchComponent";
import { MyClassesMenuOptionEnum } from "../domain/enum/MyClassesMenuOptionEnum";
import { ReportFilter } from "./Reports/ReportFilter";

export const PaginationLayout = (props: PaginationLayoutProps) => {
  const {
    pagingInfo, isLoadingList, reloadRef,searchActionRef, isFilterWordMode,selectedMyClassesMenuOption
  } = useContext(MainContext);


  return (
    <>
      <Button
        type="button"
        ref={reloadRef}
        className="d-none"
        onClick={() => props.reload(1)} />
        <Button
          type="button"
          ref={searchActionRef}
          className="d-none"
          onClick={() => props.reload(1, true)}
        />
      {isLoadingList == true && (
        <SkeletonComponent />
      )}

      {isLoadingList == false && pagingInfo.totalRecords == 0 &&!isFilterWordMode && <CenarioVazio />}

      {isLoadingList == false && pagingInfo.totalRecords == 0 &&isFilterWordMode && <EmptySearchComponent />}


      {isLoadingList == false && pagingInfo.totalRecords > 0 && (
        <Col className="wrapper-row">
          <Row>
            {props.children}
          </Row>
          <Row className="mb-4">
            <Col md="auto" className="mx-auto mb-2">
              <PaginationButtons />
            </Col>
            {
              props.subtitle
            }
          </Row>
        </Col>
      )}
    </>
  );
};
interface PaginationLayoutProps {
  reload: (page: number, isSearchTextMode?:boolean) => void;
  children: ReactNode;
  subtitle?: ReactNode;
}



