import { ApiInterface, ApiPaginatedResponse, BrainzTokenService } from "@brainz_group/webappframework";
import { AxiosResponse } from "axios";
import { ClassInfoViewModel, PastClassInfoViewModel } from "../domain/viewModel/ClassInfoViewModel";

const tokenService = BrainzTokenService.getInstance()

export default class ClassListService {

// #region ClassesHappeningToday
    public async listClassesThatWillHappenToday(page: number, pageSize: number = 24,filterMonitorsClasses:boolean = false, filterClassName : string = ""): Promise<ApiPaginatedResponse<ClassInfoViewModel> | undefined> {
        try {
            let endpoint = `/TodayTeamClasses/${tokenService.getInstitutionId()}/ListLaterToday?page=${page}&pageSize=${pageSize}`;
            if(filterMonitorsClasses){
                endpoint += "&filterMonitor=true"
            }
            if(filterClassName!=""){
                endpoint += `&filterClassName=${filterClassName}`
            }
            let response: AxiosResponse<ApiPaginatedResponse<ClassInfoViewModel>> = await ApiInterface.get(endpoint);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    public async listClassesHappeningNow(page: number, pageSize: number = 24,filterMonitorsClasses:boolean = false, filterClassName : string = ""): Promise<ApiPaginatedResponse<ClassInfoViewModel> | undefined> {
        try {
            let endpoint = `/TodayTeamClasses/${tokenService.getInstitutionId()}/ListNow?page=${page}&pageSize=${pageSize}`;
            if(filterMonitorsClasses){
                endpoint += "&filterMonitor=true"
            }
            if(filterClassName!=""){
                endpoint += `&filterClassName=${filterClassName}`
            }
            let response: AxiosResponse<ApiPaginatedResponse<ClassInfoViewModel>> = await ApiInterface.get(endpoint);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    public async listClassesThatAreLate(page: number, pageSize: number = 24,filterMonitorsClasses:boolean = false, filterClassName : string = ""): Promise<ApiPaginatedResponse<ClassInfoViewModel> | undefined> {
        try {
            let endpoint = `/TodayTeamClasses/${tokenService.getInstitutionId()}/ListLate?page=${page}&pageSize=${pageSize}`;
            if(filterMonitorsClasses){
                endpoint += "&filterMonitor=true"
            }
            if(filterClassName!=""){
                endpoint += `&filterClassName=${filterClassName}`
            }
            let response: AxiosResponse<ApiPaginatedResponse<ClassInfoViewModel>> = await ApiInterface.get(endpoint);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    
    // #endregion ClassesHappeningToday
    
    // #region PastClasses

    public async listClassesThatAreFinished(page: number, pageSize: number = 24,filterMonitorsClasses:boolean = false, filterClassName : string = ""): Promise<ApiPaginatedResponse<PastClassInfoViewModel> | undefined> {
        try {
            let endpoint = `/PreviousTeamClasses/${tokenService.getInstitutionId()}/ListFinishedToday?page=${page}&pageSize=${pageSize}`;

            if(filterMonitorsClasses){
                endpoint += "&filterMonitor=true"
            }
            if(filterClassName!=""){
                endpoint += `&filterClassName=${filterClassName}`
            }

            let response: AxiosResponse<ApiPaginatedResponse<PastClassInfoViewModel>> = await ApiInterface.get(endpoint);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    public async listClassesThatHappenedThisWeek(page: number, pageSize: number = 24,filterMonitorsClasses:boolean = false, filterClassName : string = ""): Promise<ApiPaginatedResponse<PastClassInfoViewModel> | undefined> {
        try {
            let endpoint = `/PreviousTeamClasses/${tokenService.getInstitutionId()}/ListThisWeek?page=${page}&pageSize=${pageSize}`;

            if(filterMonitorsClasses){
                endpoint += "&filterMonitor=true"
            }
            if(filterClassName!=""){
                endpoint += `&filterClassName=${filterClassName}`
            }

            let response: AxiosResponse<ApiPaginatedResponse<PastClassInfoViewModel>> = await ApiInterface.get(endpoint);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    public async listClassesThatHappenedLastWeek(page: number, pageSize: number = 24,filterMonitorsClasses:boolean = false, filterClassName : string = ""): Promise<ApiPaginatedResponse<PastClassInfoViewModel> | undefined> {
        try {
            let endpoint = `/PreviousTeamClasses/${tokenService.getInstitutionId()}/ListLastWeek?page=${page}&pageSize=${pageSize}`;

            if(filterMonitorsClasses){
                endpoint += "&filterMonitor=true"
            }
            if(filterClassName!=""){
                endpoint += `&filterClassName=${filterClassName}`
            }

            let response: AxiosResponse<ApiPaginatedResponse<PastClassInfoViewModel>> = await ApiInterface.get(endpoint);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

// #endregion PastClasses

// #region SheduledClasses

public async listClassesWillHappenTomorrow(page: number, pageSize: number = 24,filterMonitorsClasses:boolean = false, filterClassName : string = ""): Promise<ApiPaginatedResponse<ClassInfoViewModel> | undefined> {
    try {
        let endpoint = `/ScheduledTeamClasses/${tokenService.getInstitutionId()}/ListTomorrow?page=${page}&pageSize=${pageSize}`;

        if(filterMonitorsClasses){
            endpoint += "&filterMonitor=true"
        }
        if(filterClassName!=""){
            endpoint += `&filterClassName=${filterClassName}`
        }

        let response: AxiosResponse<ApiPaginatedResponse<ClassInfoViewModel>> = await ApiInterface.get(endpoint);

        return response.data;
    } catch (error) {
        console.error(error);
    }
}

public async listClassesWillHappenThisWeek(page: number, pageSize: number = 24,filterMonitorsClasses:boolean = false, filterClassName : string = ""): Promise<ApiPaginatedResponse<ClassInfoViewModel> | undefined> {
    try {
        let endpoint = `/ScheduledTeamClasses/${tokenService.getInstitutionId()}/ListThisWeek?page=${page}&pageSize=${pageSize}`;

        if(filterMonitorsClasses){
            endpoint += "&filterMonitor=true"
        }
        if(filterClassName!=""){
            endpoint += `&filterClassName=${filterClassName}`
        }

        let response: AxiosResponse<ApiPaginatedResponse<ClassInfoViewModel>> = await ApiInterface.get(endpoint);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

public async listClassesWillHappenNextWeek(page: number, pageSize: number = 24,filterMonitorsClasses:boolean = false, filterClassName : string = ""): Promise<ApiPaginatedResponse<ClassInfoViewModel> | undefined> {
    try {
        let endpoint = `/ScheduledTeamClasses/${tokenService.getInstitutionId()}/ListNextWeek?page=${page}&pageSize=${pageSize}`;

        if(filterMonitorsClasses){
            endpoint += "&filterMonitor=true"
        }
        if(filterClassName!=""){
            endpoint += `&filterClassName=${filterClassName}`
        }

        let response: AxiosResponse<ApiPaginatedResponse<ClassInfoViewModel>> = await ApiInterface.get(endpoint);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

public async listClassesWithIssues(page: number, pageSize: number = 24,filterMonitorsClasses:boolean = false, filterClassName : string = ""): Promise<ApiPaginatedResponse<ClassInfoViewModel> | undefined> {
    try {
        let endpoint = `/IssuedClass/GetIssuedClasses?page=${page}&pageSize=${pageSize}`;

        if(filterMonitorsClasses){
            endpoint += "&filterMonitor=true"
        }
        if(filterClassName!=""){
            endpoint += `&filterClassName=${filterClassName}`
        }

        let response: AxiosResponse<ApiPaginatedResponse<ClassInfoViewModel>> = await ApiInterface.get(endpoint);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

// #endregion SheduledClasses

}


