import React, { useState, useEffect, FC, useCallback, Fragment } from "react";
import { Card, Col, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import { TertiaryBlueButton, TertiaryRedButton } from "../../Button/styles";
import MeetingDetailsModal from "../../Modal/MeetingDetailsModal";
import { MainCardStyles, PaginatedSearchCard } from "../Styles";
import JoinUrlErrorModal from "../../Modal/JoinUrlErrorModal";

interface Props {
  date?: any;
  descrpiton?: any;
  name?: any;
  teachers?: any;
  startTime?: any;
  endTime?: any;
  erroMessage:string
}

const CardClassWithIssue: React.FC<Props> = ({
  date,
  descrpiton,
  name,
  teachers,
  startTime,
  endTime,
  erroMessage
}) => {
  const [showModal, setShowModal] = useState(false)
  const [showJoinUrlErrorModal, setShowInfoJoinUrlErrorModal] = useState(false)

  return (
    <PaginatedSearchCard>
      <Card.Header>
        <Row>
          <Col md="auto" className="pe-0">
            <i className="bi bi-exclamation-triangle error"/>
          </Col>
          <Col md="auto" className="ps-2 pe-0 my-auto">
            <span className="date-text error">{date}</span>
          </Col>
          <Col md="auto" className="ms-auto ps-0 my-auto">
            <span className="hour-text">
                {startTime} - {endTime}
            </span>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <h1>
              {name}
            </h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>
              {descrpiton}
            </h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <span>
              {teachers}
            </span>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer>
        <Row>
          <Col md="auto" className="ms-auto mt-auto me-0 my-auto">
            <Row>
              <Col md="auto">
                <TertiaryRedButton onClick={()=>setShowInfoJoinUrlErrorModal(true)}>
                  <i className="bi bi-exclamation-triangle"/>
                </TertiaryRedButton>
              </Col>
              <Col md="auto">
                <TertiaryBlueButton onClick={()=>setShowModal(true)}>
                  <i className="bi bi-info-circle"/>
                </TertiaryBlueButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Footer>
      <MeetingDetailsModal
        date={date}
        descrpiton={descrpiton}
        name={name}
        teachers={teachers}
        startTime={startTime}
        endTime={endTime}
        showModal={showModal}
        closeModal={()=>setShowModal(false)}
      /> 
      <JoinUrlErrorModal
        showModal={showJoinUrlErrorModal}
        closeModal={()=>setShowInfoJoinUrlErrorModal(false)}
        errorMessage={erroMessage}
      />  
    </PaginatedSearchCard>
  );
};
export default CardClassWithIssue;


