import React, { useContext, useEffect } from "react";
import CardAgendado from "../../../components/Cards/CardAgendado/Card";
import { Col} from "react-bootstrap";
import { MainContext } from "../../../context/MainContext";
import { PaginationLayout } from "../../PaginationLayout";

const ClassesThatWillHappenThisWeekListComponent: React.FC = ({}) => {
  const {
    classes, 
    currentPage, 
    loadClassesWillHappenThisWeek,
    getSearchInputTextValue} = useContext(MainContext)

  useEffect(() => {
    let isFiltering:boolean = getSearchInputTextValue()!==""

    loadClassesWillHappenThisWeek(currentPage,isFiltering)
  }, [currentPage]);

  return (
    <PaginationLayout
      reload={loadClassesWillHappenThisWeek}
    >
      {classes.map((item: any) => (
        <Col md={"auto"} className="pe-0">
          <CardAgendado
            countStudents={item.countStudents}
            date={item.date}
            descrpiton={item.description}
            startTime={item.startTime}
            endTime={item.endTime}
            name={item.name}
            teachers={item.teachers}
            joinUrl={item.joinUrl}
            countTeachers={item.countTeachers}
          />
        </Col>
      ))}
    </PaginationLayout>
  );
};
export default ClassesThatWillHappenThisWeekListComponent;
