import { createRoot } from 'react-dom/client';
import "./index.css";
import "./assets/css/styles.css";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import App from "./App";

const container = document.getElementById('root');

if(container!==null){
  const root = createRoot(container);
  root.render(<App/>);
}


const htmlTag: HTMLElement = document.getElementsByTagName("html")[0];

htmlTag.translate = false;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
