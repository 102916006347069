import React from "react";
import { Col, Row, Modal } from "react-bootstrap";
import { MainModalStyles } from "./styles";

interface MeetingDetailsModalProps {
  date?: string;
  descrpiton: string;
  name?: string;
  teachers?: string;
  joinUrl?: string;
  startTime?: any;
  endTime?: any;
  showModal: boolean;
  closeModal: ()=> void;
}

export default (props: MeetingDetailsModalProps) => {
  console.log(props.joinUrl);
  
  return (
    <MainModalStyles show={props.showModal} onHide={props.closeModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          Detalhes da Reunião
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Modal.Title>
              Data e horário agendados
            </Modal.Title>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              {props.date?.split(",")[1]} - {props.startTime} às {props.endTime}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Modal.Title>
              Título
            </Modal.Title>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              {props.name}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Modal.Title>
              Equipe
            </Modal.Title>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              {props.descrpiton}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Modal.Title>
              Professor(es)
            </Modal.Title>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              {props.teachers}
            </p>
          </Col>
        </Row>
        {
          (props.joinUrl!== undefined&&props.joinUrl!=="")&&
          <>
            <Row>
              <Col>
                <a href={props.joinUrl} target="_blank">
                  <Modal.Title>
                    Link de Acesso
                    <i className="ms-2 bi bi-box-arrow-up-right"/>
                  </Modal.Title>
                </a>
              </Col>
            </Row>
          </>
        }
      </Modal.Body>
    </MainModalStyles>
  );
};


