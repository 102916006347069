import React, { useState} from "react";
import { Card, Col, Row } from "react-bootstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BsCheck2Circle, BsExclamationDiamond } from "react-icons/bs";
import { TertiaryBlueButton } from "../../Button/styles";
import MeetingDetailsModal from "../../Modal/MeetingDetailsModal";
import { PaginatedSearchCard } from "../Styles";
import { PastClassPunctualityStatusEnum } from "../../../domain/enum/PastClassStatusEnum";
import styled from "styled-components";
import { PastClassFinalizationStatusEnum } from "../../../domain/enum/PastClassFinalizationStatusEnum";

interface Class {
  code?: React.ReactNode;
  id?: Number;
  name?: any;
}
interface Props {
  countStudents: number;
  countTeachers?: number;
  date?: any;
  descrpiton?: any;
  name?: any;
  teachers?: any;
  startTime?: any;
  endTime?: any;
  teacherParticipation: number;
  punctualityClassStatus:any;
  finalizationClassStatus:any;
}
const CardFootstep: React.FC<Props> = ({
  countStudents,
  teacherParticipation,
  descrpiton,
  name,
  teachers,
  startTime,
  endTime,
  date,
  punctualityClassStatus,
  finalizationClassStatus
}) => {
  let Icons = () => {
    switch(finalizationClassStatus.id){
      case PastClassFinalizationStatusEnum.Regular:
        return <BsCheck2Circle color="#008555" size={30} />;
      case PastClassFinalizationStatusEnum.Unfulfilled:
        return <AiOutlineCloseCircle color="#D43B26" size={30} />;
      case PastClassFinalizationStatusEnum.FinishedEarly:
        return <BsExclamationDiamond color="#b0ab28" size={30} />;

    }
  };
  let Name = () => {
    switch(finalizationClassStatus.id){
      case PastClassFinalizationStatusEnum.Regular:
        return <span style={{ color: "#008555" }}>{date}</span>;
      case PastClassFinalizationStatusEnum.Unfulfilled:
        return <span style={{ color: "#D43B26" }}> {date}</span>;
      case PastClassFinalizationStatusEnum.FinishedEarly:
        return <span style={{ color: "#b0ab28" }}>{date}</span>;
    }
  };
  const [showModal, setShowModal] = useState(false)

  let GetStatus = () => {
    switch(punctualityClassStatus.id){
      case PastClassPunctualityStatusEnum.Regular:
        return <PastClassStatusStyles color="#008555" className="text-center mx-auto">
          <span>Regular</span>
        </PastClassStatusStyles>;
      
      // aula atrasada
      case PastClassPunctualityStatusEnum.LateClass:
        return <PastClassStatusStyles color="#A56300" className="text-center mx-auto">
        <span>Atraso Normal</span>
      </PastClassStatusStyles>;

      case PastClassPunctualityStatusEnum.CriticalLateness:
          return <PastClassStatusStyles color="#D43B26"  className="text-center mx-auto">
          <span>Atraso Crítico</span>
        </PastClassStatusStyles>;
      // atraso crítico

      case PastClassPunctualityStatusEnum.Unfulfilled:
        return <PastClassStatusStyles color="#727171" className="text-center mx-auto">
        <span>Não Realizada</span>
      </PastClassStatusStyles>;
    }
  }
  
  
  return (
    <PaginatedSearchCard>
      <Card.Header>
        <Row>
          <Col md={"auto"} className="pe-0">
            {Icons()}
          </Col>
          <Col md={"auto"} className="ps-2 pe-0 my-auto">
            <span className="date-text">{Name()}</span>
          </Col>
          <Col md={"auto"} className="ms-auto ps-0 my-auto">
            <span className="hour-text">
              {startTime} - {endTime}
            </span>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <h1>{name}</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>{descrpiton}</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <span>{teachers}</span>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer>
        <Row>
          <Col md="auto" className="pe-0">
            <Row className="mx-0">
              <Col md="auto" className="mx-auto">
                <span>Professor</span>
              </Col>
            </Row>
            <Row className="mx-0">
              <Col md="auto" className="mx-auto">
                  <span
                  className="mx-auto"
                  style={
                    teacherParticipation >= 50
                      ? { color: "#008555" }
                      : { color: "#A56300" }
                  }
                >
                  {teacherParticipation.toFixed()+"%"}
                </span>
              </Col>
            </Row>
          </Col>
          <Col md="auto" className="ps-0">
            <Row className="mx-0">
              <Col md="auto" className="mx-auto">
                <span>Alunos </span>
              </Col>
            </Row>
            <Row className="mx-0">
              <Col md="auto" className="mx-auto">
                <span
                  className="mx-auto"
                  style={
                    countStudents >= 50
                      ? { color: "#008555" }
                      : { color: "#A56300" }
                  }
                >
                  {countStudents+"%"}
                </span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row className="mx-0">
              <Col className="me-auto">
                <span>Status </span>
              </Col>
            </Row>
            <Row className="mx-0">
              {GetStatus()}
            </Row>
          </Col>
          <Col md="auto" className="ms-auto mt-auto me-0">
            <TertiaryBlueButton onClick={()=>setShowModal(true)}>
              <i className="bi bi-info-circle" />
            </TertiaryBlueButton>
          </Col>
        </Row>
      </Card.Footer>
      <MeetingDetailsModal
        date={date}
        descrpiton={descrpiton}
        name={name}
        teachers={teachers}
        startTime={startTime}
        endTime={endTime}
        showModal={showModal}
        closeModal={()=>setShowModal(false)}
      /> 
    </PaginatedSearchCard>
  );
};
export default CardFootstep;

export const PastClassStatusStyles = styled(Col)<{color:string}>`

  border-radius: 5px;
  border: 1px solid ${p=> p.color};
  span{
    font-size:1.7vmin!important;
    color:${p=> p.color}!important;
  }
`

