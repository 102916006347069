import styled from "styled-components";
import { Modal } from "react-bootstrap";

export const MainModalStyles = styled(Modal)`
  .modal-content{
    background: #FFFFFF;
    .modal-header{
      background: #FFFFFF;
      box-shadow: 0px 0.28vmin 0.56vmin rgba(0, 0, 0, 0.1);
      .modal-title{
        font-family: 'Segoe UI';
        font-style: normal;
        font-weight: 600;
        font-size: 2.22vmin;
        line-height: 21px;

        color: #3D3D3D;
      }
      button{
        padding:0vmin 4vmin 1vmin 1vmin;
        span{
          font-size:6vmin!important;
          font-weight:400;
        }
      }
    }
    .modal-body{
      .modal-title{
        font-family: 'Segoe UI Bold';
        font-size: 1.97vmin;
        line-height: 24px;
        color: #3D3D3D;
        margin-bottom:1.5vmin;
      }
      h3{
        font-family: 'Segoe UI Bold';
        font-size: 2.5vmin;
        line-height: 24px;
        color: #3D3D3D;
        margin-bottom:1.5vmin;
      }
      a{
        text-decoration:none;
      }
      p{
        font-family: 'Segoe UI';
        font-style: normal;
        font-weight: normal;
        font-size: 2.22vmin;
        line-height: 2.8vmin;
        /* identical to box height, or 100% */
        color: #1F1F1F;
      }
      .bi-exclamation-triangle{
        color:red;
        font-size:8vmin;
      }
    }
  }
`;
