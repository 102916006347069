import { createContext, ReactNode, useEffect, useRef, useState } from "react";
import { MonitorMainTabsEnum } from "../domain/enum/MonitorMainTabsEnum";
import { MyClassesMenuOptionEnum } from "../domain/enum/MyClassesMenuOptionEnum";
import { ClassInfoViewModel, PastClassInfoViewModel } from "../domain/viewModel/ClassInfoViewModel";
import ClassListService from "../services/ClassesService";
import { PageLoader, PagingInfo } from "@brainz_group/webappframework";
import { ReportViewModel } from "../domain/viewModel/ReportViewModel";
import ReportService, { AttendanceReportPayload } from "../services/ReportService";
import GroupMonitorService from "../services/GroupMonitorService";
import { ReportCreationStatus } from "../domain/enum/ReportCreationStatus";


const classService = new ClassListService()
const groupMonitorService = new GroupMonitorService();
const reportService = new ReportService();

interface MainContextData {
    selectedHeaderTab: MonitorMainTabsEnum
    setSelectedHeaderTab: React.Dispatch<React.SetStateAction<MonitorMainTabsEnum>>

    selectedMyClassesMenuOption: MyClassesMenuOptionEnum
    setSelectedMyClassesMenuOption: React.Dispatch<React.SetStateAction<MyClassesMenuOptionEnum>>

    classes: ClassInfoViewModel[]|PastClassInfoViewModel[]
    setClasses: React.Dispatch<React.SetStateAction<ClassInfoViewModel[]|PastClassInfoViewModel[]>>

    reports:ReportViewModel[]
    setReports: React.Dispatch<React.SetStateAction<ReportViewModel[]>>

    pagingInfo: PagingInfo
    setPagingInfo: React.Dispatch<React.SetStateAction<PagingInfo>>

    currentPage: number
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>

    isLoadingList: boolean
    setIsLoadingList: React.Dispatch<React.SetStateAction<boolean>>

    isFilterWordMode: boolean
    setIsFilterWordMode: React.Dispatch<React.SetStateAction<boolean>>

    userHasClasses: boolean
    setUserHasClasses: React.Dispatch<React.SetStateAction<boolean>>

    startDate:Date
    setStartDate:React.Dispatch<React.SetStateAction<Date>>

    endDate:Date
    setEndDate:React.Dispatch<React.SetStateAction<Date>>

    loadClassesThatWillHappenToday: (page: number, isSearchingText?:boolean) => void
    loadClassesHappeningNow: (page: number, isSearchingText?:boolean) => void
    loadClassesThatAreLate: (page: number, isSearchingText?:boolean) => void
    loadClassesThatAreFinished: (page: number, isSearchingText?:boolean) => void

    loadClassThatHappenedThisWeekList: (page: number, isSearchingText?:boolean) => void
    loadClassThatHappenedLastWeekList: (page: number, isSearchingText?:boolean) => void

    loadClassesWillHappenTomorrow: (page: number, isSearchingText?:boolean) => void
    loadClassesWillHappenThisWeek: (page: number, isSearchingText?:boolean) => void
    loadClassesWillHappenNextWeek: (page: number, isSearchingText?:boolean) => void
    loadClassesWithIssues: (page: number, isSearchingText?:boolean) => void

    loadReports: (page: number, isSearchingText?:boolean) => void
    createAttendanceReport: (start:Date, end:Date) => void

    searchActionRef: React.MutableRefObject<null>
    reloadRef: React.MutableRefObject<null>
    searchInputRef: React.MutableRefObject<null>

    onChangeDatePickerChange:(dates:any) => void

    createReport: () => void
    isValidReport: () => boolean


    reloadData: () => void
    searchData: () => void
    getSearchInputTextValue:() => string
    clearSearch: () =>void
    changeMenuOptions: (option: MyClassesMenuOptionEnum) => void
    reRender: () => void
}

export const MainContext = createContext({} as MainContextData)

interface MainContextProviderProps {
    children: ReactNode
}

export const MainContextProvider: React.FC<MainContextProviderProps> = ({ children }) => {

    const [selectedHeaderTab, setSelectedHeaderTab] = useState<MonitorMainTabsEnum>(MonitorMainTabsEnum.MyClasses)

    const [selectedMyClassesMenuOption, setSelectedMyClassesMenuOption] = useState<MyClassesMenuOptionEnum>(MyClassesMenuOptionEnum.Today)

    const [classes, setClasses] = useState<any>([]);

    const [reports, setReports] = useState<ReportViewModel[]>([] as ReportViewModel[]);


    const [pagingInfo, setPagingInfo] = useState<PagingInfo>({} as PagingInfo);

    const [currentPage, setCurrentPage] = useState(1);

    const [isLoading, setIsLoading] = useState(true);

    const [isLoadingList, setIsLoadingList] = useState(true);

    const [userHasClasses, setUserHasClasses] = useState(false);

    const [reload, callReload] = useState<boolean>(true)

    const reloadRef = useRef(null as any)
    const searchActionRef = useRef(null as any)

    const searchInputRef = useRef(null as any)

    const [isFilterWordMode, setIsFilterWordMode] = useState(false)
    const [startDate, setStartDate] = useState<Date>(null as any);
    const [endDate, setEndDate] = useState<Date>(null as any);


    useEffect(() => {
        pageLoad()
    }, [])

    useEffect(() => {
        setIsLoadingList(true)
        setCurrentPage(1)
        clearSearchInputTextValue()
        setIsFilterWordMode(false)
        reloadData()
    }, [selectedHeaderTab])


    async function pageLoad() {
        let response = await groupMonitorService.getMonitorsGroups()
        if (response?.success && response.result.length > 0) {
            myAllClasses()
            return
        }
        loadAllClasses()
    }

    function loadAllClasses() {
        setIsLoading(false)
        setSelectedHeaderTab(MonitorMainTabsEnum.AllLessons)
    }

    function myAllClasses() {
        setIsLoading(false)
        setSelectedHeaderTab(MonitorMainTabsEnum.MyClasses)
        setUserHasClasses(true)
    }



    const reRender = () => { callReload(!reload) }

    const changeMenuOptions = (option: MyClassesMenuOptionEnum) => {
        if (option !== selectedMyClassesMenuOption) {

            setIsLoadingList(true)
            setCurrentPage(1)
            setSelectedMyClassesMenuOption(option)
            clearSearchInputTextValue()
            setIsFilterWordMode(false)
        }
    }
    const searchData = ()=>{
        setIsFilterWordMode(true)
        searchDataAction()
    }

    const clearSearch = ()=>{
        clearSearchInputTextValue()
        setIsFilterWordMode(false)
        loadData()
    }

    const reloadData = () => {
        if(isFilterWordMode){
            searchDataAction()
        }else{
            loadData()
        }
    }

    const loadData = ()=>{
        if (reloadRef.current !== null) {
            let button: HTMLButtonElement = reloadRef.current
            button.click()
        }
    }

    const searchDataAction = () => {
        if (searchActionRef.current !== null) {
            let button: HTMLButtonElement = searchActionRef.current
            button.click()
        }
    }

    function getSearchInputTextValue():string{
        try {
            let textInput: HTMLInputElement = searchInputRef.current

            return textInput.value;

        } catch (error) {
            
            console.error(error);
            return "" 
        }
    }

    function clearSearchInputTextValue(){
        try {
            let textInput: HTMLInputElement = searchInputRef.current
            textInput.value = ""

        } catch (error) {
            
            console.error(error);
            return "" 
        }
    }

    // #region ClassesHappeningToday

    async function loadClassesThatWillHappenToday(page: number, isSearchingText:boolean = false) {
        setIsLoadingList(true);
        let searchTextFilter:string = ""

        if(isSearchingText){

            searchTextFilter = getSearchInputTextValue()
        }

        let response = await classService.listClassesThatWillHappenToday(page, 24, selectedHeaderTab === MonitorMainTabsEnum.MyClasses, searchTextFilter)
        if (response?.success) {
            setClasses(response.result);
            setPagingInfo(response.paging);
            setIsLoadingList(false);
        }
    }

    async function loadClassesHappeningNow(page: number, isSearchingText:boolean = false) {
        setIsLoadingList(true);
        let searchTextFilter:string = ""

        if(isSearchingText){

            searchTextFilter = getSearchInputTextValue()
        }

        let response = await classService.listClassesHappeningNow(page, 24, selectedHeaderTab === MonitorMainTabsEnum.MyClasses, searchTextFilter)
        if (response?.success) {
            setClasses(response.result);
            setPagingInfo(response.paging);
            setIsLoadingList(false);
        }
    }

    async function loadClassesThatAreLate(page: number, isSearchingText:boolean = false) {
        setIsLoadingList(true);
        let searchTextFilter:string = ""

        if(isSearchingText){

            searchTextFilter = getSearchInputTextValue()
        }

        let response = await classService.listClassesThatAreLate(page, 24, selectedHeaderTab === MonitorMainTabsEnum.MyClasses, searchTextFilter)
        if (response?.success) {
            setClasses(response.result);
            setPagingInfo(response.paging);
            setIsLoadingList(false);
        }
    }

    // #endregion ClassesHappeningToday

    // #region SheduleClasses

    async function loadClassesWillHappenTomorrow(page: number, isSearchingText:boolean = false) {
        setIsLoadingList(true);

        let searchTextFilter:string = ""

        if(isSearchingText){

            searchTextFilter = getSearchInputTextValue()
        }

        let response = await classService.listClassesWillHappenTomorrow(page, 24, selectedHeaderTab === MonitorMainTabsEnum.MyClasses, searchTextFilter)
        if (response?.success) {
            setClasses(response.result);
            setPagingInfo(response.paging);
            setIsLoadingList(false);
        }
    }

    async function loadClassesWillHappenThisWeek(page: number, isSearchingText:boolean = false) {
        setIsLoadingList(true);

        let searchTextFilter:string = ""

        if(isSearchingText){

            searchTextFilter = getSearchInputTextValue()
        }

        let response = await classService.listClassesWillHappenThisWeek(page, 24, selectedHeaderTab === MonitorMainTabsEnum.MyClasses, searchTextFilter)
        if (response?.success) {
            setClasses(response.result);
            setPagingInfo(response.paging);
            setIsLoadingList(false);
        }
    }

    async function loadClassesWillHappenNextWeek(page: number, isSearchingText:boolean = false) {
        setIsLoadingList(true);

        let searchTextFilter:string = ""

        if(isSearchingText){

            searchTextFilter = getSearchInputTextValue()
        }

        let response = await classService.listClassesWillHappenNextWeek(page, 24, selectedHeaderTab === MonitorMainTabsEnum.MyClasses, searchTextFilter)
        if (response?.success) {
            setClasses(response.result);
            setPagingInfo(response.paging);
            setIsLoadingList(false);
        }
    }

    async function loadClassesWithIssues(page: number, isSearchingText:boolean = false) {
        setIsLoadingList(true);

        let searchTextFilter:string = ""

        if(isSearchingText){

            searchTextFilter = getSearchInputTextValue()
        }

        let response = await classService.listClassesWithIssues(page, 24, selectedHeaderTab === MonitorMainTabsEnum.MyClasses, searchTextFilter)
        if (response?.success) {
            setClasses(response.result);
            setPagingInfo(response.paging);
            setIsLoadingList(false);
        }
    }
    // #endregion SheduleClasses

    // #region PastClasses

    async function loadClassesThatAreFinished(page: number, isSearchingText:boolean = false) {
        setIsLoadingList(true);
        let searchTextFilter:string = ""

        if(isSearchingText){

            searchTextFilter = getSearchInputTextValue()
        }

        let response = await classService.listClassesThatAreFinished(page, 24, selectedHeaderTab === MonitorMainTabsEnum.MyClasses, searchTextFilter)
        if (response?.success) {
            setClasses(response.result);
            setPagingInfo(response.paging);
            setIsLoadingList(false);
        }
    }

    async function loadClassThatHappenedThisWeekList(page: number, isSearchingText:boolean = false) {
        setIsLoadingList(true);
        let searchTextFilter:string = ""

        if(isSearchingText){

            searchTextFilter = getSearchInputTextValue()
        }

        let response = await classService.listClassesThatHappenedThisWeek(page, 24, selectedHeaderTab === MonitorMainTabsEnum.MyClasses, searchTextFilter)
        if (response?.success) {
            setClasses(response.result);
            setPagingInfo(response.paging);
            setIsLoadingList(false);
        }
       
    }

    async function loadClassThatHappenedLastWeekList(page: number, isSearchingText:boolean = false) {
        setIsLoadingList(true);

        let searchTextFilter:string = ""

        if(isSearchingText){

            searchTextFilter = getSearchInputTextValue()
        }

        let response = await classService.listClassesThatHappenedLastWeek(page, 24, selectedHeaderTab === MonitorMainTabsEnum.MyClasses, searchTextFilter)
        if (response?.success) {
            setClasses(response.result);
            setPagingInfo(response.paging);
            setIsLoadingList(false);
        }
    }



    // #endregion PastClasses

    async function createAttendanceReport(start:Date, end: Date){

        setIsLoadingList(true);
        let payload:AttendanceReportPayload ={
            start:start.toISOString(),
            end:end.toISOString()
        } 

        let response = await reportService.createAttendanceReport(payload)

        if (response?.success) {
            loadReports(1)
        }else{
            setIsLoadingList(false)
        }
        
    }

    async function loadReports(page: number, isSearchingText:boolean = false) {
        //setIsLoadingList(true);

        let searchTextFilter:string = ""

        if(isSearchingText){

            searchTextFilter = getSearchInputTextValue()
        }

        let response = await reportService.loadReports(page)
        if (response?.success) {
            setReports(response.result);
            setPagingInfo(response.paging);
            let test = response.result.find((item)=>item.status === ReportCreationStatus.Pending)
            if(test!== undefined){
                setTimeout(function(){
                    loadReports(1) 
                  }, 5000);
            }
            setIsLoadingList(false);
        }
    }

    const onChangeDatePickerChange = (dates:any) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
      };

    function createReport(){
    createAttendanceReport(startDate,endDate)
    }

    function isValidReport():boolean{
      if(startDate === null || endDate === null){
        return false
      }
      if(endDate < startDate){
        return false
      }
      if(endDate > new Date(Date.now())){
        return false
      }
      return true
    }

    return (
        <MainContext.Provider
            value={{
                selectedHeaderTab, setSelectedHeaderTab,
                selectedMyClassesMenuOption, setSelectedMyClassesMenuOption,
                classes, setClasses,
                reports, setReports,
                pagingInfo, setPagingInfo,
                currentPage, setCurrentPage,
                isLoadingList, setIsLoadingList,
                userHasClasses, setUserHasClasses,
                isFilterWordMode, setIsFilterWordMode,
                startDate, setStartDate,
                endDate, setEndDate,

                loadClassesThatWillHappenToday,
                loadClassesHappeningNow,
                loadClassesThatAreLate,
                loadClassesThatAreFinished,

                loadClassThatHappenedThisWeekList,
                loadClassThatHappenedLastWeekList,

                loadClassesWillHappenTomorrow,
                loadClassesWillHappenThisWeek,
                loadClassesWillHappenNextWeek,
                loadClassesWithIssues,

                loadReports,
                createAttendanceReport,
                createReport,
                isValidReport,
                onChangeDatePickerChange,

                changeMenuOptions,
                getSearchInputTextValue,
                reloadRef,
                searchActionRef,
                searchInputRef,
                reloadData,
                searchData,
                clearSearch,

                reRender
            }}
        >
            {
                isLoading ?
                    (
                        <PageLoader 
                            spinnerColor="#46747E"
                        />
                    )
                    :
                    (
                        children
                    )
            }
        </MainContext.Provider>
    )
}



