import { ApiInterface, ApiPaginatedResponse, ApiResponse } from "@brainz_group/webappframework";
import { AxiosResponse } from "axios";
import { ReportViewModel } from "../domain/viewModel/ReportViewModel";


export default class ReportService {
    public async createAttendanceReport(payload:AttendanceReportPayload): Promise<ApiResponse<ReportViewModel> | undefined>{
        try {
            let endpoint = `/Report/AttendanceReport`;
            let response: AxiosResponse<ApiResponse<ReportViewModel>> = await ApiInterface.post(endpoint, payload);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }
    public async loadReports(page: number, pageSize: number = 6): Promise<ApiPaginatedResponse<ReportViewModel> | undefined> {
        try {
            let endpoint = `/Report/GetUserReports?page=${page}&pageSize=${pageSize}`;
            let response: AxiosResponse<ApiPaginatedResponse<ReportViewModel>> = await ApiInterface.get(endpoint);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }
}


export interface AttendanceReportPayload {
    start: string;
    end:   string;
}

