import LayoutDefault from "../components/layouts/LayoutDefault";
import { Route ,BrowserRouter, Routes } from "react-router-dom";
function BrainzRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LayoutDefault />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default BrainzRouter;






