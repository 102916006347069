import React, {useContext, useEffect } from "react";
import { Col } from "react-bootstrap";
import CardFootstep from "../../../components/Cards/CardFootstep/CardFootstep";
import { MainContext } from "../../../context/MainContext";
import { PaginationLayout } from "../../PaginationLayout";
import { PastClassSubtitle } from "../PastClassSubtitle";

const ClassesThatHappenedThisWeekListComponent: React.FC = ({}) => {
  const {
    classes, 
    currentPage, 
    loadClassThatHappenedThisWeekList,
    getSearchInputTextValue} = useContext(MainContext)

  useEffect(() => {
    let isFiltering:boolean = getSearchInputTextValue()!==""

    loadClassThatHappenedThisWeekList(currentPage,isFiltering)
  }, [currentPage]);


  return (
    <PaginationLayout
      reload={loadClassThatHappenedThisWeekList}
      subtitle={
        <PastClassSubtitle
          classes={classes}
        />
      }
    >

      {classes.map((item: any) => (
        <Col md={"auto"} className="pe-0">
          <CardFootstep
            countStudents={item.percentStudent}
            teacherParticipation={item.teacherParticipation}
            date={item.date}
            descrpiton={item.description}
            startTime={item.startTime}
            endTime={item.endTime}
            name={item.name}
            teachers={item.teachers}
            punctualityClassStatus={item.punctualityClassStatus}
            finalizationClassStatus={item.finalizationClassStatus}

          />
        </Col>
      ))}
    </PaginationLayout>
  );
};

export default ClassesThatHappenedThisWeekListComponent;
