import React, { useContext, useEffect } from "react";
import CardAgendado from "../../../components/Cards/CardAgendado/Card";
import { Col } from "react-bootstrap";
import { MainContext } from "../../../context/MainContext";
import { PaginationLayout } from "../../PaginationLayout";

const ClassesThatWillHappenNextWeekListComponent: React.FC = ({}) => {
  const {
    classes, 
    currentPage, 
    loadClassesWillHappenNextWeek,
    getSearchInputTextValue} = useContext(MainContext)

  useEffect(() => {
    let isFiltering:boolean = getSearchInputTextValue()!==""

    loadClassesWillHappenNextWeek(currentPage, isFiltering)
  }, [currentPage]);

  return (
    <PaginationLayout
      reload={loadClassesWillHappenNextWeek}
    >
      {classes.map((item: any) => (
        <Col md={"auto"} className="pe-0">
          <CardAgendado
            countStudents={item.countStudents}
            date={item.date}
            descrpiton={item.description}
            startTime={item.startTime}
            endTime={item.endTime}
            name={item.name}
            joinUrl={item.joinUrl}

            teachers={item.teachers}
            countTeachers={item.countTeachers}
          />
        </Col>
      ))}
    </PaginationLayout>
  );
};
export default ClassesThatWillHappenNextWeekListComponent;
