import { Col, Container } from 'react-bootstrap';
import styled from 'styled-components';

// #region Header

export const MainHeaderStyles = styled.header`
    top:0px;
    min-width: 100%;
    z-index:500;
    background: #F9F9F9;
    min-height: 8.8vmin;
    box-shadow: 0px 0.14vmin 0.84vmin rgba(81, 79, 78, 0.2);
    position:fixed;
    h1{
        cursor:default;
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.2vmin;
        line-height: 3.33vmin;
        color: #46747E;
        margin-bottom:0px;
    }


    .col-nav-bar{
        z-index:100;
        max-width:80vw;
        min-height: 8.8vmin;
        .nav-link{
            min-height: 8.8vmin;
            font-family: Lato;
            font-style: normal;
            font-weight: 400;
            font-size: 2.22vmin;
            line-height: 3.33vmin;
            background-color:transparent;
            color: #514F4E!important;
            &.active{
                background-color:transparent;
                i{
                    color: #46747E;
                }
                span{
                    color: #46747E;
                }

            }
            &:hover{
                background:#D2DDDE;
            }
            &.disabled{
                i{
                    color: #E4E3E3;
                }
                span{
                    color: #E4E3E3;
                }
            }
        }
        .nav-link.active{
            background-color:transparent;
            border-bottom:4px solid #46747E;
            font-weight: 600;

            &:hover{
                background:#D2DDDE;
            }
        }
    }
    i{
        font-size:3vmin;
    }

    .update-time{
        span{
            font-family: 'Segoe UI';
            font-style: normal;
            font-weight: 400;
            font-size: 1.97vmin;
            line-height: 19px;
            color: #727171; 
        }
    }
    .bi-arrow-repeat{
        font-size:4vmin;
    }
    .bi-box-arrow-right{
        font-size:4vmin;
    }
`

// #endregion Header

// #region BaseLayout

export const PaginatedSearchResults = styled(Col)`
  .pagination{
    margin-top:5vmin;
    .page-item{
      .page-link{
        padding:1vmin 2vmin;
        background:transparent;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 2.22vmin;
        line-height: 24px;
        color: #514F4E;
        border:none;
      }
      &.active{
        .page-link{
            color: #46747E;
            border: 0.14vmin solid #46747E;
            border-radius: 0.56vmin;
        }
      }
      &.disabled{
        .page-link{
            color:#B9B8B8;
        }
      }
    }
  }
`

export const ControlPanelPageStyles = styled(Container)`
  position:relative;
  top:8.8vmin;
  max-height:91vh;
  h1 {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    width: 100%;
    color: #514f4e;
  }
  .content-row{
    min-height:50vh
  }
  .wrapper-row{
    max-height:71vh;
    overflow-y:scroll;
    ::-webkit-scrollbar{
      position:absolute;
      width: 1.5vmin;
      background: #E8E8E8;
  }

  ::-webkit-scrollbar-thumb{
      background:#B9B8B8;
  }
  ::-webkit-scrollbar-thumb:hover{
      background: #46747E!important;
  }  
  }
`;

// #endregion BaseLayout





