import React, {useState } from "react";
import Header from "./Header";
import "./default.css";
import { Container } from "react-bootstrap";
import { MainContextProvider } from "../../context/MainContext";
import { ControlPanelPageStyles } from "./styles";
import { ClasseScreenComponent } from "../../screens/ClasseScreenComponent";

const LayoutDefaultWrapper = ()=>{
  return(
    <MainContextProvider>
      <LayoutDefault/>
    </MainContextProvider>
  )
}

const LayoutDefault: React.FC = () => {

  return (
    <>
      <Header />
      <ControlPanelPageStyles fluid={true}>
        <ClasseScreenComponent/>
      </ControlPanelPageStyles>
    </>
  );
};


export default LayoutDefaultWrapper;



