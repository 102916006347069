import React, { useContext, useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import styled from "styled-components";
import { SecondaryGreenButton } from "../../components/Button/styles";
import { PaginationLayout } from "../PaginationLayout";
import { ReportViewModel } from "../../domain/viewModel/ReportViewModel";
import { MainContext } from "../../context/MainContext";
import { ReportCreationStatus } from "../../domain/enum/ReportCreationStatus";



export function ReportsComponent({ }: ReportsComponentProps) {

  const {
    reports, 
    currentPage , 
    
    loadReports,
    createAttendanceReport
  
  } = useContext(MainContext)

  useEffect(() => {

    loadReports(currentPage)

  }, [currentPage]);


  function download(url:string) {
    const a = document.createElement('a')
    a.href = url
    a.download = url.split('/').pop()!
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  return (
  <PaginationLayout
    reload={loadReports}
  >
    <ReportsComponentStyles className="mt-2 me-5">
      <Row className="mt-4">
        <Col>
          {
            reports.map((item)=>{
              return(
                <Row className="mb-4">
                  <Col  md={10}>
                    <Row className="report-name">
                      <span className="my-auto">
                        {item.name}
                      </span>
                    </Row>
                  </Col>
                  <Col>
                    <SecondaryGreenButton  disabled={item.status!=ReportCreationStatus.Created} className="download-button px-2" onClick={() => {download(item.fileUrl)}}>
                      {
                        item.status== ReportCreationStatus.Created?
                        (
                        <Row>
                          <Col md='auto' className="ms-auto my-auto">
                            Baixar
                          </Col>
                          <Col md='auto' className="ps-0 me-auto">
                            <i className="bi bi-download"/>
                          </Col>
                        </Row>
                        )
                        :
                        (
                          <Row>
                            <Col className="my-auto">
                              Gerando
                            </Col>
                            <Col md='auto' className="ps-0">
                              <Spinner animation="border" variant="secondary" />
                            </Col>
                          </Row>
                        )
                      }

                    </SecondaryGreenButton>
                  </Col>
                </Row>
              )
            })
          }
        </Col>
      </Row>
    </ReportsComponentStyles>
  </PaginationLayout>
  );
}
interface ReportsComponentProps {

}

const ReportsComponentStyles = styled(Col)`
  button{
    height:6.5vh;
    weight:100%;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 2.22vmin;
    line-height: 2.91vmin;
    i{
      font-size: 2.5vmin;

    }
  }
  .download-button{
    width:100%;
  }
  .report-name{
    height:6.5vh;
    background: #FFFFFF;
    border: 1px solid #E7E7E7;
    border-radius: 0.6vmin;
  }
  span{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 2vmin;
    line-height: 2vmin;
    color: #656565;
  }
  .react-datepicker-wrapper{
    input{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 1.7vmin;

      background: #FFFFFF;
      border: 1px solid #E4E3E3;
      border-radius: 0.6vmin;

      font-family: 'Segoe UI';
      font-style: normal;
      font-weight: 400;
      font-size: 2vmin;      
      color: #656565;

    }
  }
`;





