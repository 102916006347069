import React, { useContext, useEffect } from "react";
import { Col } from "react-bootstrap";
import { MainContext } from "../../../context/MainContext";
import { PaginationLayout } from "../../PaginationLayout";
import CardClassWithIssue from "../../../components/Cards/CardClassWithIssue/Card";

const ClassesWithIssuesListComponent: React.FC = ({}) => {
  const {
    classes, 
    currentPage, 
    loadClassesWithIssues,
    getSearchInputTextValue
  } = useContext(MainContext)

  useEffect(() => {
    let isFiltering:boolean = getSearchInputTextValue()!==""

    loadClassesWithIssues(currentPage,isFiltering)
  }, [currentPage]);

  return (
    <PaginationLayout
      reload={loadClassesWithIssues}
    >
      {
        classes.map((item: any) => (
          <Col md={"auto"} className="pe-0">
            <CardClassWithIssue
              date={item.date}
              descrpiton={item.description}
              startTime={item.startTime}
              endTime={item.endTime}
              name={item.name}
              teachers={item.teachers}
              erroMessage={item.erroMessage}            />
          </Col>
      ))}
    </PaginationLayout>
  );
};
export default ClassesWithIssuesListComponent;
