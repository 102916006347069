import React, { useContext } from "react";
import {
  Col,
  Row,
} from "react-bootstrap";
import { ReactComponent as UserGroup } from "../../assets/icons/Group.svg";

import styled from "styled-components";
import { MainContext } from "../../context/MainContext";
import { MyClassesMenuOptionEnum } from "../../domain/enum/MyClassesMenuOptionEnum";

const CenarioVazio: React.FC = ({}) => {
  const {
    selectedMyClassesMenuOption
  } = useContext(MainContext);
  return (
    <EmptyResult className="my-auto">
      <Row>
        <Col md="auto" className="mx-auto">
          <UserGroup />
        </Col>
      </Row>
      <Row>
        <Col md="auto" className="mx-auto mt-3">
          <h1>
            {
              selectedMyClassesMenuOption === MyClassesMenuOptionEnum.Reports
              ?
              'Nenhum relatório encontrado'
              :
              'Nenhuma aula encontrada'
            } 
          </h1>
        </Col>
      </Row>
      <Row>
        <Col md="auto" className="mx-auto">
            <span>
              Tente atualizar os dados ou voltar mais tarde
            </span>
        </Col>
      </Row>
    </EmptyResult>
  );
};
export default CenarioVazio;

const EmptyResult = styled(Col)`
  h1{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 2.22vmin;
    line-height: 3.33vmin,;
    text-align: center;
    color: #656565;
  }
  span{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 1.97vmin;
    line-height: 24px;
    text-align: center;
    color: #656565;
  }
`
