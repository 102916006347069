import {BrainzAuthContextProvider} from '@brainz_group/webappframework/';
import BrainzRouter from "./brainzRouter/BrainzRouter";

export default function App(){
    return(
    <div className="App">
        <BrainzAuthContextProvider
            waitAuthentication={true}
            //spinnerColor="#46747E"
        >
            <BrainzRouter/>
        </BrainzAuthContextProvider>
    </div>
    )
}