import { BrainzAuthContext } from "@brainz_group/webappframework";
import { useContext } from "react";
import { Card, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { TertiaryGreenButton } from "../Button/styles";

interface ProfileMenuProps {
  showProfileMenu: boolean;
}

const ProfileMenu = (props: ProfileMenuProps) => {
  const {logout} = useContext(BrainzAuthContext)
  return (
    <ProfileMenuStyles className={props.showProfileMenu ? "" : "d-none"}>
      <Card.Body className="mx-3 mb-1">
        <Row>
          <Col>
            <h1>Olá!</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>O que deseja fazer?</h5>
          </Col>
        </Row>
        <Row className="mt-1">
          <TertiaryGreenButton className="py-3" onClick={() => logout()}>
            <Row>
              <Col md={"auto"} className="pe-0">
                <i className="bi bi-box-arrow-left"/>
              </Col>
              <Col md={"auto"}>
                <span>
                    Sair do Monitor de Aulas
                </span>
              </Col>
            </Row>
          </TertiaryGreenButton>
        </Row>
      </Card.Body>
    </ProfileMenuStyles>
  );
};

const ProfileMenuStyles = styled(Card)`
  position: fixed;
  top: 8.2vmin;
  right: 0px;
  z-index: 1000;
  background: #ffffff;
  box-shadow: 0px 0.28vmin 0.56vmin rgba(0, 0, 0, 0.25);
  border-radius: 1.12vmin 0px 1.12vmin 1.12vmin;
  h1 {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 2.22vmin;
    line-height: 2.77vmin;
    color: #514f4e;
  }
  h5 {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 2.22vmin;
    line-height: 2.77vmin;
    color: #514f4e;
  }
  button {
    background-color: #fff;
    border: none !important;
    box-shadow: none !important;

    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 2.22vmin;
    line-height: 2.64vmin;
    :hover {
      background-color: #e0e1f4;
    }
    :active {
      background-color: #e0e1f4 !important;
    }
    :focus {
      background-color: #fff !important;
      border: 1px solid #0c8292 !important;
      box-sizing: border-box;
    }
    i {
      font-size: 2.5vmin;
    }
  }
`;

export default ProfileMenu;
