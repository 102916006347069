import { Nav } from 'react-bootstrap';
import styled from 'styled-components';



export const MainStyleNavBar = styled(Nav)`
    .nav-link{
        font-family: Lato;
        font-style: normal;
        font-weight: 400;
        font-size: 2.22vmin;
        line-height: 3.33vmin;
        background-color:transparent;
        color: #514F4E!important;
        &:active{
            background-color:transparent;

        }
        &:hover{
            background:#D2DDDE;
        }
    }
    .nav-link.active{
        color: #46747E!important;
        background-color:transparent;
        border-bottom:4px solid #46747E;
        font-weight: 600;
        &:hover{
            background:#D2DDDE;
        }
    }
`;
 