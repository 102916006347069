import { Card } from "react-bootstrap";
import styled from "styled-components";

export const MainCardStyles = styled(Card)`
  background: #FFFFFF;
  border: 0.14vmin solid #E4E3E3;
  box-shadow: 0px 0.28vmin 0.56vmin rgba(103, 103, 103, 0.1);
  border-radius: 0.56vmin;
  .card-header{
    background: #FFFFFF;
    padding-left: 1vmin;
    padding-right: 1vmin;
    padding-bottom: 0.5vmin;

    .date-text{
      font-family: 'Segoe UI';
      font-style: normal;
      font-weight: 400;
      font-size: 1.97vmin;
      line-height: 2.22vmin;
      color: #195A7E;
    }
    .hour-text{
      font-family: 'Segoe UI';
      font-style: normal;
      font-weight: 400;
      font-size: 1.97vmin;
      line-height: 2.22vmin;
      text-align: right;

      color: #5C5C5C;
    }
    i{
      font-size:3vmin;
    }
    .bi-calendar3{
      color:#195A7E;
    }
    .error{
      color:red;
    }

  }
  .card-footer{
    background: #FFFFFF;
    padding-top:1.3vmin;
    padding-bottom:0.5vmin;
    padding-right:1vmin;

    i{
      color:#46747E;
      font-size:2.5vmin;
    }
    span{
      font-family: 'Segoe UI';
      font-style: normal;
      font-weight: 400;
      font-size: 1.97vmin;
      line-height: 19px;
      color: #5C5C5C;
    }
    span.mx-auto{
      color: #656565;
      padding-bottom:0.7vmin;
      font-weight: 600;

    }

  }
  .card-body{
    background: #FFFFFF;
    padding: 1.5vmin 1vmin;
    h1{
      max-width:45vh;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      font-family: 'Segoe UI Bold';
      font-style: normal;
      font-weight: 500;
      font-size:  1.97vmin;
      line-height: 2.22vmin;


      color: #4A4A4A;
    }
    h2{
      max-width:45vh;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      font-family: 'Segoe UI';
      font-style: normal;
      font-weight: 400;
      font-size:  1.97vmin;
      line-height: 2.22vmin;

      color: #4A4A4A;
    }
    span{
      max-width:45vh;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      font-family: 'Segoe UI';
      font-style: normal;
      font-weight: 400;
      font-size:  1.97vmin;
      line-height: 2.22vmin;

      color: #6B6B6B;

    }
  }
`;

export const PaginatedSearchCard = styled(MainCardStyles)`
  width:26vw;
  max-width:47vh;
  margin-top:3vmin;
`
