import React, { useCallback, useContext, useState } from "react";
import { Col, Row, Nav } from "react-bootstrap";

import ClassesHappeningNowListComponent from "./ClassesHappeningNow/ClassesHappeningNowListComponent";
import { MainStyleNavBar } from "../../components/NavBar/styles";
import ClassesThatAreLateListComponent from "./ClassesThatAreLate/ClassesThatAreLateListComponent";
import ClassesThatWillHappenListComponent from "./ClassesThatWillHappen/ClassesThatWillHappenListComponent";
import ClassesThatAreFinishedListComponent from "./ClassesThatAreFinished/ClassesThatAreFinishedListComponent";
import { MainContext } from "../../context/MainContext";

const TodayClasses: React.FC = () => {
  const [activeTab, changeActiveTab] = useState(1);
  const {
    setIsLoadingList, 
    setCurrentPage
  } = useContext(MainContext)

  const handleChangeActiveTab = (tab:number)=>{
    setIsLoadingList(true)
    setCurrentPage(1)
    changeActiveTab(tab)
  }

  const CallBack = useCallback(() => {
    if (activeTab == 1) {
      return <ClassesHappeningNowListComponent />;
    } else if (activeTab == 2) {
      return <ClassesThatWillHappenListComponent />;
    } else if (activeTab == 3) {
      return <ClassesThatAreLateListComponent />;
    } else if (activeTab == 4) {
      return <ClassesThatAreFinishedListComponent />;
    } else {
      return <div></div>;
    }
  }, [activeTab]);

  return (
      <Col>
        <Row>
          <Col>
            <MainStyleNavBar
              variant="pills"
              defaultActiveKey="/home"
            >
              <Nav.Item className="mx-1">
                <Nav.Link
                  active={activeTab === 1}
                  onClick={() => handleChangeActiveTab(1)}
                  className="pb-2"
                >
                  Agora
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="mx-1">
                <Nav.Link
                  active={activeTab === 3}
                  onClick={() => handleChangeActiveTab(3)}
                  className="pb-2"
                >
                  Atrasadas
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="mx-1">
                <Nav.Link
                  active={activeTab === 2}
                  onClick={() => handleChangeActiveTab(2)}
                  className="pb-2"
                >
                  Ainda Hoje
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="mx-1">
                <Nav.Link
                  active={activeTab === 4}
                  onClick={() => handleChangeActiveTab(4)}
                  className="pb-2"
                >
                  Finalizadas
                </Nav.Link>
              </Nav.Item>
            </MainStyleNavBar>
          </Col>
        </Row>
        <Row className="content-row">
          <CallBack />
        </Row>
      </Col>
  );
};

export default TodayClasses;
