import React, { useState, useEffect, FC, useCallback, Fragment } from "react";
import { Card, Col, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {
  FaCheck,
  FaTimes,
  FaUserCheck,
  FaRegCalendarAlt,
} from "react-icons/fa";
import { GiAlarmClock } from "react-icons/gi";
import { RiErrorWarningLine } from "react-icons/ri";
import { TertiaryBlueButton, TertiaryRedButton } from "../../Button/styles";
import MeetingDetailsModal from "../../Modal/MeetingDetailsModal";
import { MainCardStyles, PaginatedSearchCard } from "../Styles";
import JoinUrlErrorModal from "../../Modal/JoinUrlErrorModal";
import { TodayClassStatusEnum } from "../../../domain/enum/TodayClassStatusEnum";

interface Class {
  code?: React.ReactNode;
  id?: number;
  name?: any;
}
interface Props {
  percentStudents: number;
  countTeachers?: number;
  date?: any;
  descrpiton?: any;
  name?: any;
  teachers?: any;
  startTime?: any;
  endTime?: any;
  statusClass: Class;
  teacherParticipated: any;
  hasJoinUrl:boolean;
  joinUrl:string;
  channelWebUrl:string;


}

const CardToday: React.FC<Props> = ({
  percentStudents,
  teacherParticipated,
  descrpiton,
  name,
  teachers,
  startTime,
  endTime,
  statusClass,
  date,
  hasJoinUrl,
  joinUrl
}) => {
  const [showInfoModal, setInfoShowModal] = useState(false)
  const [showJoinUrlErrorModal, setShowInfoJoinUrlErrorModal] = useState(false)

  function OpenChannelThread() {
    window.open(
    joinUrl, "_blank");
  }

  let Icons = () => {
    switch(statusClass.id){
      // atraso crítico
      case TodayClassStatusEnum.CriticalDelay:
        return <i className="bi bi-x-circle"  style={{color:"#D43B26", fontSize:"3vmin"}}/>;
      
      // aula atrasada
      case TodayClassStatusEnum.LateClass:
        return <i className="bi bi-alarm"  style={{color:"#A56300", fontSize:"3vmin"}}/>;

      // acontecendo agora
      case TodayClassStatusEnum.HappeningNow:
        return <i className="bi bi-check-circle"  style={{color:"#008555", fontSize:"3vmin"}}/>;

      // alunos não convidados
      case TodayClassStatusEnum.UninvitedStudents:
        return <i className="bi bi-x-circle"  style={{color:"#D43B26", fontSize:"3vmin"}}/>;

      // professor não convidados
      case TodayClassStatusEnum.UninvitedTeachers:
        return <i className="bi bi-x-circle"  style={{color:"#D43B26", fontSize:"3vmin"}}/>;

      // aula sem convidados
      case TodayClassStatusEnum.ClassWithoutGuests:
        return <i className="bi bi-x-circle"  style={{color:"#D43B26", fontSize:"3vmin"}}/>;

      // aula acontecendo fora do horário
      case TodayClassStatusEnum.ClassTakingPlaceOutOfHours:
        return <i className="bi bi-person-check"  style={{color:"#008555", fontSize:"3vmin"}}/>;

      // professor ausente
      case TodayClassStatusEnum.MissingTeacher:
        return <i className="bi bi-person-dash"  style={{color:"#b0ab28", fontSize:"3vmin"}}/>;

      // aula finalizada mais cedo
        case TodayClassStatusEnum.ClassFinishedEarly:
          return <i className="bi bi-check-circle"  style={{color:"#29bc87", fontSize:"3vmin"}}/>;
      default:
        return <></>
    }
  };
  let Name = () => {
    switch(statusClass.id){
      // atraso crítico
      case TodayClassStatusEnum.CriticalDelay:
        return <span style={{ color: "#D43B26" }}> {statusClass.name}</span>;
      
      // aula atrasada
      case TodayClassStatusEnum.LateClass:
        return <span style={{ color: "#A56300" }}>{statusClass.name}</span>;

      // acontecendo agora
      case TodayClassStatusEnum.HappeningNow:
        return <span style={{ color: "#008555" }}>{statusClass.name}</span>;

      // alunos não convidados
      case TodayClassStatusEnum.UninvitedStudents:
        return <span style={{ color: "#D43B26" }}>{statusClass.name}</span>;

      // professor não convidados
      case TodayClassStatusEnum.UninvitedTeachers:
        return <span style={{ color: "#D43B26" }}>{statusClass.name}</span>;

      // aula sem convidados
      case TodayClassStatusEnum.ClassWithoutGuests:
        return <span style={{ color: "#D43B26" }}>{statusClass.name}</span>;

      // aula acontecendo fora do horário
      case TodayClassStatusEnum.ClassTakingPlaceOutOfHours:
        return <span style={{ color: "#008555" }}>{statusClass.name}</span>;

      // professor ausente
      case TodayClassStatusEnum.MissingTeacher:
        return <span style={{ color: "#b0ab28" }}>{statusClass.name}</span>;

      // aula finalizada mais cedo
        case TodayClassStatusEnum.ClassFinishedEarly:
          return <span style={{ color: "#29bc87" }}>{statusClass.name}</span>;

      default:
        return <></>
    }
  };
  return (
    <PaginatedSearchCard>
      <Card.Header>
        <Row>
          <Col md={"auto"}  className="pe-0 my-auto">
            {Icons()}
          </Col>
          <Col md={"auto"} className="pl-2 mt-auto mb-2">
            <span className="date-text">{Name()}</span>
          </Col>
          <Col md={"auto"} className="ms-auto my-auto">
              <span className="hour-text">
                {startTime} - {endTime}
              </span>
            </Col>
        </Row>
      </Card.Header>
      <Card.Body>
      <Row>
        <Col>
          <h1>
            {name}
          </h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>
            {descrpiton}
          </h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            {teachers}
          </span>
        </Col>
      </Row>
      </Card.Body>
      <Card.Footer>
        <Row>
          <Col md="auto" className="px-0">
            <Row className="mx-0 mb-1">
              <span>
                Professor
              </span>
            </Row>
            <Row className="mx-0">
              <Col md="auto" className="mx-auto">
                <span className="mx-auto">
                  {teacherParticipated ? (
                    <FaCheck color="#008555" />
                  ) : (
                    <FaTimes color="#D43B26" />
                  )}
                </span>
              </Col>
            </Row>
          </Col>
          <Col md="auto" className="px-0">
            <Row className="mx-0 mb-1">
              <span>Alunos </span>

            </Row>
            <Row className="mx-0">
              <Col md="auto" className="mx-auto">
                <span className="mx-auto" style={(percentStudents >= 50)?({ color: "#008555"}):({ color: "#A56300"})}>
                  {percentStudents.toString()+"%"}
                </span>
              </Col>
            </Row>
          </Col>
          <Col md="auto" className="ms-auto mt-auto me-0 my-auto">
            <Row>
              {
                !hasJoinUrl&&
                  <Col>
                    <TertiaryRedButton onClick={()=>setShowInfoJoinUrlErrorModal(true)}>
                      <i className="bi bi-exclamation-triangle"/>
                    </TertiaryRedButton>
                  </Col>
              }
              <Col>
                <TertiaryBlueButton title="Acesse a equipe do Teams" onClick={()=>OpenChannelThread()}>
                  <i className="bi bi-chat-dots"/>
                </TertiaryBlueButton>
              </Col>
              <Col>
                <TertiaryBlueButton onClick={()=>setInfoShowModal(true)}>
                  <i className="bi bi-info-circle"/>
                </TertiaryBlueButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Footer>
      <MeetingDetailsModal
        date={date}
        descrpiton={descrpiton}
        name={name}
        teachers={teachers}
        startTime={startTime}
        endTime={endTime}
        showModal={showInfoModal}
        joinUrl={joinUrl}
        closeModal={()=>setInfoShowModal(false)}
      />
      {/* <JoinUrlErrorModal
        showModal={showJoinUrlErrorModal}
        closeModal={()=>setShowInfoJoinUrlErrorModal(false)}
      />             */}
    </PaginatedSearchCard>
  );
};
export default CardToday;
