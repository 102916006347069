import React, { useState, useEffect, FC, useCallback, Fragment } from "react";
import { Card, Col, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import { TertiaryBlueButton, TertiaryRedButton } from "../../Button/styles";
import MeetingDetailsModal from "../../Modal/MeetingDetailsModal";
import { MainCardStyles, PaginatedSearchCard } from "../Styles";
import JoinUrlErrorModal from "../../Modal/JoinUrlErrorModal";

interface Props {
  countStudents: Number;
  countTeachers: Number;
  date?: any;
  descrpiton?: any;
  name?: any;
  teachers?: any;
  startTime?: any;
  endTime?: any;
  joinUrl?:string;
}

const CardAgendado: React.FC<Props> = ({
  countStudents,
  countTeachers,
  date,
  descrpiton,
  name,
  teachers,
  startTime,
  endTime,
  joinUrl
}) => {
  const [showModal, setShowModal] = useState(false)
  const [showJoinUrlErrorModal, setShowInfoJoinUrlErrorModal] = useState(false)

  function OpenChannelThread() {
    window.open(
    joinUrl, "_blank");
  }
  return (
    <PaginatedSearchCard>
      <Card.Header>
        <Row>
          <Col md="auto" className="pe-0">
            <i className="bi bi-calendar3"/>
          </Col>
          <Col md="auto" className="ps-2 pe-0 my-auto">
            <span className="date-text">{date}</span>
          </Col>
          <Col md="auto" className="ms-auto ps-0 my-auto">
            <span className="hour-text">
                {startTime} - {endTime}
            </span>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <h1>
              {name}
            </h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>
              {descrpiton}
            </h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <span>
              {teachers}
            </span>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer>
        <Row>
          <Col md="auto" className="px-0">
            <Row className="mx-0 mb-2">
              <Col md="auto" className="mx-auto">
                <span>
                  Professor
                </span>
              </Col>
            </Row>
            <Row>
              <Col md="auto" className="mx-auto">
                <span className="mx-auto">
                  {countTeachers.toString()}
                </span>
              </Col>
            </Row>
          </Col>
          <Col md="auto" className="mx-0">
            <Row className="mx-0 mb-2">
              <Col md="auto" className="mx-auto">
                <span>
                  Alunos
                </span>
              </Col>
            </Row>
            <Row>
              <Col md="auto" className="mx-auto">
                <span className="mx-auto">{countStudents.toString()}</span>
              </Col>
            </Row>
          </Col>
          <Col md="auto" className="ms-auto mt-auto me-0 my-auto">
            <Row>
              {
                (joinUrl === undefined||joinUrl === null|| joinUrl.trim().length ===0)&&
                <Col md="auto">
                  <TertiaryRedButton onClick={()=>setShowInfoJoinUrlErrorModal(true)}>
                    <i className="bi bi-exclamation-triangle"/>
                  </TertiaryRedButton>
                </Col>
              }
              {
                (joinUrl !== undefined&&joinUrl !== null&& joinUrl.trim().length >0)&&
                <Col md="auto" className="px-0">
                  <TertiaryBlueButton title="Acesse a equipe do Teams" onClick={()=>OpenChannelThread()}>
                    <i className="bi bi-chat-dots"/>
                  </TertiaryBlueButton>
                </Col>
              }
              <Col md="auto">
                <TertiaryBlueButton onClick={()=>setShowModal(true)}>
                  <i className="bi bi-info-circle"/>
                </TertiaryBlueButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Footer>
      <MeetingDetailsModal
        date={date}
        descrpiton={descrpiton}
        name={name}
        teachers={teachers}
        startTime={startTime}
        endTime={endTime}
        showModal={showModal}
        joinUrl={joinUrl}
        closeModal={()=>setShowModal(false)}
      /> 
      {/* <JoinUrlErrorModal
        showModal={showJoinUrlErrorModal}
        closeModal={()=>setShowInfoJoinUrlErrorModal(false)}
      />   */}
    </PaginatedSearchCard>
  );
};
export default CardAgendado;


