import { Col, Row, Modal } from "react-bootstrap";
import { MainModalStyles } from "./styles";

interface JoinUrlErrorModalProps {
  showModal: boolean;
  closeModal: ()=> void;
  errorMessage:string
}

export default (props: JoinUrlErrorModalProps) => {

  return (
    <MainModalStyles show={props.showModal} onHide={props.closeModal} centered>
      <Modal.Header closeButton>

      </Modal.Header>
      <Modal.Body>
        <Row>
            <Col md="auto" className="mx-auto">
                <i className="bi bi-exclamation-triangle"/>
            </Col>
        </Row>
        <Row>
            <Col md="auto" className="mx-auto">
                <h3>
                    PROFESSOR SEM URL JOIN
                </h3>
            </Col>
        </Row>
        <Row>
            <Col md="auto" className="mx-auto">
                <p>
                    {props.errorMessage}             
                </p>
            </Col>
        </Row>
      </Modal.Body>
    </MainModalStyles>
  );
};





