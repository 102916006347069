export enum TodayClassStatusEnum {
  CriticalDelay = 1,
  LateClass = 2,
  HappeningNow = 3,
  UninvitedStudents = 4,
  UninvitedTeachers = 5,
  ClassWithoutGuests = 6,
  ClassTakingPlaceOutOfHours = 7,
  MissingTeacher = 8,
  ClassFinishedEarly = 9

}


