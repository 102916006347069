import React, { useState, useEffect, FC, useCallback, Fragment } from "react";
import {
  Card,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
  Spinner,
} from "react-bootstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  FaCheck,
  FaTimes,
  FaUserCheck,
  FaRegCalendarAlt,
} from "react-icons/fa";
import { GiAlarmClock } from "react-icons/gi";
import { RiErrorWarningLine } from "react-icons/ri";
import "./center.css";
import { ReactComponent as Spin } from "../../assets/icons/Spin.svg";
import { ReactComponent as UserGroup } from "../../assets/icons/Group.svg";

interface Class {
  code?: React.ReactNode;
  id?: Number;
  name?: any;
}
const SkeletonComponent: React.FC = ({}) => {
  return (
    <Col className="my-auto">
      <Row>
        <Col md="auto" className="mx-auto">
          <Spinner color="#46747E" animation="border" />
        </Col>
      </Row>
      <Row>
        <Col md="auto" className="mx-auto">
          <span style={{ color: "#656565", fontSize: 16, fontWeight: 600 }}>
            Carregando...
          </span>
        </Col>
      </Row>
    </Col>
  );
};
export default SkeletonComponent;
