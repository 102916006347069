import { Button } from 'react-bootstrap';
import styled from 'styled-components';

export const PrimaryGreenButton = styled(Button)`
    background: #46747E;
    border:none;
    border-radius: 1.11vmin;
    box-shadow:none;
    color:#FFFFFF;
    i{
        color: #FFFFFF;
    }
    span{
        color: #FFFFFF;
    }

    &:hover{
        background: #769399!important;
        box-shadow:none;
        border:none;
    }
    &:focus{
        background: #46747E!important;
        border: 1px solid #46747E;
        box-shadow:none;
    }
    &:active{
        background: #46747E;
        color: #FFFFFF;
        box-shadow:none!important;
        border:none;
    }
    &.active{
        background: #46747E!important;
        color: #FFFFFF;
        box-shadow:none!important;
        border:none;
        i{
            color: #FFFFFF;
        }
    }
    &:disabled{
        background: #648f98!important;
    }
`;

export const SecondaryGreenButton = styled(Button)`
    background: #FFFFFF;
    border: 0.14vmin solid #46747E;
    border-radius: 1.11vmin;
    box-shadow:none;

    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;

    color:#46747E;
    i{
        color: #46747E;
    }
    span{
        color: #46747E;
    }

    &:hover{
        background: #46747E!important;
        box-shadow:none;
        border: 0.14vmin solid #46747E;
        i{
        color: #FFFFFF;
        }
        span{
            color: #FFFFFF;
        }
    }
    &:focus{
        background: #46747E!important;
        border: 1px solid #46747E;
        box-shadow:none;
        i{
        color: #FFFFFF;
        }
        span{
            color: #FFFFFF;
        }
    }
    &:active{
        background: #FFFFFF;
        color: #46747E;
        box-shadow:none!important;
        border: 0.14vmin solid #46747E;
    }
    &.active{
        background: #FFFFFF!important;
        color: #46747E;
        box-shadow:none!important;
        border: 0.14vmin solid #46747E;
        i{
            color: #46747E;
        }
    }
    &:disabled{

        background: #FFFFFF!important;
        color:#6c757d;
        border: 0.14vmin solid #6c757d;
        i{
            color: #6c757d;
        }
    }
`;

export const TertiaryGreenButton = styled(Button)`
    background: #F9F9F9;
    border:none;
    border-radius:0px;
    box-shadow:none;
    color:#46747E;
    i{
        color: #46747E;
    }
    span{
        color: #46747E;
    }

    &:hover{
        background: #D2DDDE!important;
        box-shadow:none;
        border:none;
    }
    &:focus{
        background: #F9F9F9!important;
        border: 1px solid #855A6E;
        box-shadow:none;
    }
    &:active{
        background: #D2DDDE;
        color: #46747E;
        box-shadow:none!important;
        border:none;
    }
    &.active{
        background: #D2DDDE!important;
        color: #46747E;
        box-shadow:none!important;
        border:none;
        i{
            color: #46747E;
        }
    }
`;

export const TertiaryBlueButton = styled(Button)`
    background: transparent;
    border:none;
    border-radius:1.11vmin;
    box-shadow:none;
    color:#4F70AF;
    i{
        color: #4F70AF;
    }
    span{
        color: #4F70AF;
    }

    &:hover{
        background: #DDE5F4!important;
        box-shadow:none;
        border:none;
    }
    &:focus{
        background: #F9F9F9!important;
        border: 1px solid #4F70AF;
        box-shadow:none;
    }
    &:active{
        background: #DDE5F4;
        color: #4F70AF;
        box-shadow:none!important;
        border:none;
    }
    &.active{
        background: #DDE5F4!important;
        color: #4F70AF;
        box-shadow:none!important;
        border:none;
        i{
            color: #4F70AF;
        }
    }
`;

export const TertiaryRedButton = styled(Button)`
    background: transparent;
    border:none;
    border-radius:1.11vmin;
    box-shadow:none;
    color:#ff1100;
    i{
        color: #ff1100!important;
    }
    span{
        color: #ff1100;
    }

    &:hover{
        background: #fac4c0!important;
        box-shadow:none;
        border:none;
    }
    &:focus{
        background: #fff0f0!important;
        border: 1px solid ##ff1100;
        box-shadow:none;
    }
    &:active{
        background: #fac4c0!important;
        color: #ff1100;
        box-shadow:none!important;
        border:none;
    }
    &.active{
        background: #fac4c0!important;
        color: #ff1100;
        box-shadow:none!important;
        border:none;
        i{
            color: #ff1100;
        }
    }
`;