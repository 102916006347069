import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Col, Row, Nav } from "react-bootstrap";

import ClassesThatWillHappenTomorrowListComponent from "./ClassesThatWillHappenTomorrow/ClassesThatWillHappenTomorrowListComponent";
import ClassesThatWillHappenThisWeekListComponent from "./ClassesThatWillHappenThisWeek/ClassesThatWillHappenThisWeekListComponent";
import ClassesThatWillHappenNextWeekListComponent from "./ClassesThatWillHappenNextWeek/ClassesThatWillHappenNextWeekListComponent";
import { MainStyleNavBar } from "../../components/NavBar/styles";
import { MainContext } from "../../context/MainContext";
import ClassesWithIssuesListComponent from "./ClassesWithIssues/ClassesWithIssues";
import { BrainzAuthContext } from "@brainz_group/webappframework";

const ScheduledClasses: React.FC = ({}) => {

  const {getInstitutionId} = useContext(BrainzAuthContext)
  const [activeTab, changeActiveTab] = useState(1);

  const {
    setIsLoadingList, 
    setCurrentPage
  } = useContext(MainContext)

  const handleChangeActiveTab = (tab:number)=>{
    setIsLoadingList(true)
    setCurrentPage(1)
    changeActiveTab(tab)
  }

  const CallBack = useCallback(() => {
    switch(activeTab){
      case 1:
        return <ClassesThatWillHappenTomorrowListComponent />;
      case 2: 
        return <ClassesThatWillHappenThisWeekListComponent />
      case 3:
        return <ClassesThatWillHappenNextWeekListComponent />
      case 4:
        return <ClassesWithIssuesListComponent /> 
      default:
        return <></>
    }
  }, [activeTab]);

  return (
      <Col>
        <Row >
          <Col>
            <MainStyleNavBar
              variant="pills"
              defaultActiveKey="/home"
              className="mb-0"
            >
              <Nav.Item className="mx-1">
                <Nav.Link
                  active={activeTab === 1}
                  onClick={() => handleChangeActiveTab(1)}
                  className="pb-2"
                >
                  Amanhã
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="mx-1">
                <Nav.Link
                  active={activeTab === 2}
                  onClick={() => handleChangeActiveTab(2)}
                  className="pb-2"
                >
                  Nesta semana
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="mx-1">
                <Nav.Link
                  active={activeTab === 3}
                  onClick={() => handleChangeActiveTab(3)}
                  className="pb-2"
                >
                  Próxima semana
                </Nav.Link>
              </Nav.Item>
              {
                //TODO: mudar verificação para ser feita através do Account InstitutionApplication
                getInstitutionId()==='9313C89C-98D4-4A33-B970-9D1C3C0E0599'.toLocaleLowerCase()&&
                  <Nav.Item className="mx-1">
                    <Nav.Link
                      active={activeTab === 4}
                      onClick={() => handleChangeActiveTab(4)}
                      className="pb-2"
                    >
                      Aulas com problemas
                    </Nav.Link>
                  </Nav.Item>
              }
              </MainStyleNavBar>
          </Col>
        </Row>
        <Row className="content-row">
          <CallBack />
        </Row>
      </Col>
  );
};

export default ScheduledClasses;
