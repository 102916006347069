import { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { MainContext } from "../context/MainContext";
import styled from "styled-components";
import { SecondaryGreenButton } from "../components/Button/styles";



export function EmptySearchComponent() {
  const {
    clearSearch
  } = useContext(MainContext);

  return (
    <EmptySearchComponentStyles className="mt-5">
      <Row className="mt-3">
        <Col md="auto" className="mx-auto">
          <img src={"/img/search-empty-woman.svg"} />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <h1>
            Nenhuma aula encontrada
          </h1>
        </Col>
      </Row>
      <Row>
        <Col md="auto" className="mx-auto mt-2">
          <SecondaryGreenButton onClick={() => clearSearch()}>
            Limpar Busca
          </SecondaryGreenButton>
        </Col>
      </Row>
    </EmptySearchComponentStyles>
  );
}
const EmptySearchComponentStyles = styled(Col)`
  h1{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 2.22vmin;
    line-height: 3.33vmin;
    text-align: center;
    color: #656565;
  }
  button{
    font-family: 'Segoe UI';
    font-style: normal;
    padding:1.2vmin;
    font-weight: 600;
    font-size: 2.22vmin;
    line-height: 3.33vmin;
  }
  img{
    height:30vmin;
  }
`;
