import { ApiInterface, ApiPaginatedResponse } from "@brainz_group/webappframework";
import { AxiosResponse } from "axios";
import { MonitorGroupsViewModel } from "../domain/viewModel/MonitorGroupsViewModel";


export default class GroupMonitorService {
    public async getMonitorsGroups(): Promise<ApiPaginatedResponse<MonitorGroupsViewModel> | undefined> {
        try {
            let endpoint = `/GroupMonitor/GetMonitorsGroups`;
            let response: AxiosResponse<ApiPaginatedResponse<MonitorGroupsViewModel>> = await ApiInterface.get(endpoint);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }
}
