import React, { useContext } from "react";
import ControlPanelSideMenu from "../components/SideMenu/ControlPanelSideMenu";
import { Row } from "react-bootstrap";
import { MainContext } from "../context/MainContext";
import { MyClassesMenuOptionEnum } from "../domain/enum/MyClassesMenuOptionEnum";
import TodayClasses from "./TodayClasses/TodayClasses";
import ScheduledClasses from "./SheduledClasses/ScheduledClasses";
import PastClasses from "./PastClasses/PastClasses";
import { PaginatedSearchResults } from "../components/layouts/styles";
import { SearchBarComponent } from "../components/SearchBar/SearchBarComponent";
import { ReportsComponent } from "./Reports/ReportsComponent";
import { ReportFilter } from "./Reports/ReportFilter";



export const ClasseScreenComponent: React.FC = () => {

  const {
    selectedMyClassesMenuOption
  } = useContext(MainContext);

  return (
    <Row>
      <ControlPanelSideMenu />
      <PaginatedSearchResults className="pl-0">
        {
          selectedMyClassesMenuOption !== MyClassesMenuOptionEnum.Reports ?
          <Row>
            <SearchBarComponent />
          </Row>
          :
          <ReportFilter/>
        }
        <Row style={{minHeight:'50vh'}}>
          {selectedMyClassesMenuOption === MyClassesMenuOptionEnum.Today &&
            <TodayClasses />
          }
          {selectedMyClassesMenuOption === MyClassesMenuOptionEnum.Scheduled &&
            <ScheduledClasses />}
          {selectedMyClassesMenuOption === MyClassesMenuOptionEnum.Past &&
            <PastClasses />}
          {selectedMyClassesMenuOption === MyClassesMenuOptionEnum.Reports &&
            <ReportsComponent />}

        </Row>
      </PaginatedSearchResults>
    </Row>
  );
};



