import styled from 'styled-components';
import { Button, Col, Container, ListGroup, ProgressBar, Row } from "react-bootstrap"


export const CollapseMenuButton = styled(Button)`   
position: absolute;
right:0px;
z-index:2;
display:grid;
border:none!important;
box-shadow:none!important;
background: #E8E8E8!important;
border-radius: 0px 0px 0px 1.12vmin;
i{
    color:#0C8292;
    font-size:3.33vmin;
}

`

export const AttendCourseSideMenuItemStyle = styled(ListGroup)`
    .list-group-item{
        border-bottom:none!important;
        padding:0px;
    }
    .bi-file-earmark-check{
        color:#218749!important;
    }
    i.bi-check2-circle{
        color:#218749!important;
    }
    i.bi-lock{
        color: #514F4E!important;
    }
    i.bi-dash-circle{
        color: #995C00!important ;
    }
    i.bi-clock{
        color: #6468C8!important ;
    }

    h3{
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.22vmin;
        line-height: 2.78vmin;
        color: #777575;
    }
    p{
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 1.95vmin;
        line-height: 2.78vmin;
        color: #777575;
        margin-bottom:10px;
    }
`
export const AttendCourseSideMenuStyle = styled(ListGroup)`
    overflow-y:scroll;
    min-height:91vmin;
    max-height:91vmin;
    height:100%;
    border:none!important;
    border-left: 0.14vmin solid #E8E8E8!important;
    background: #F9F9F9;
    
    ::-webkit-scrollbar{
        position:absolute;
        width: 0.8vmin ;
        background: #E8E8E8;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 0.4vmin ;
        background: #B9B8B8;
        width: 0.4vmin ;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background:#B9B8B8;; 
        width: 1.12vmin ;

    }

    .list-group-item{
        background-color:#F9F9F9;
        padding:0px;
        border:none;
        border-bottom: 0.14vmin solid #E8E8E8;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
        button{
            .col{
                text-align:left;
            }
            background-color:#F9F9F9;
            border:none!important;
            box-shadow:none!important;
            :hover:enabled{
                background:#E8E8E8;
            }
            :active:enabled{
                background-color:#ffd4c8;

            }
            i{
                font-size: 3.33vmin;
                color: #EA694C;
            }

            width:100%;
            background-color:#F9F9F9;
            border:none!important;
            box-shadow:none!important;

        }
        button.selectedModule{
            border-left: 5px solid #EA694C!important;
        }
        button.collapsedSelection{
            background: #FCE9E4;
            &:hover{
                background:#E8E8E8;
                
            }
        }
    }
    .list-group-item.lesson{
        background-color:#FFFFFF!important;
        button{
            background-color:#FFFFFF;

            &:active:enabled{
                background-color: #ffd4c8;
            }
            &:hover:enabled{
                background-color:#E8E8E8!important;
            }

            i{
                font-size: 3.33vmin;
                color: #EA694C;
            }
        }
    }
    .list-group-item:first-child{
        background-color:#F9F9F9;
        button{
            font-family: Lato;
            font-style: normal;
            font-weight: 600;
            font-size: 2.22vmin;
            line-height: 2.64vmin;

            /* Brand/Tertiary/Medium */

            p.hide{
                margin-bottom:0px;
                color: #0C8292!important;
            }
            i{
                font-size: 3.33vmin;
                color: #0C8292;
            }
        }

    }

    .list-group-item:last-child{
        border-bottom:none!important;
    }

    h1{
        margin-bottom:0px;
        
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.5vmin;
        line-height: 3.33vmin;
        color: #514F4E;
    }

    h2{
        margin-bottom:0px;

        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.22vmin;
        line-height: 2.77vmin;
        color: #636060;
    }

    .btn.active{
        background-color:#FCE9E4!important;
    }
    i.bi-lock{
        color: #514F4E
    }
    i.bi-check2-circle{
    color:#218749!important;
}
`

export const ManageCourseSideMenuStyles = styled(Col)`
    height:91.1vh;
    .row.fixed-bottom{
        margin:0px;
        max-width:20%;
    }
    .list-group-item{
        background-color:transparent;
        border:none;
    }
    button{
        width:23.33vmin;
        height:6.66vmin;
        background-color:transparent;
        border:none;
        box-shadow:none!important;
        border-radius:0.56vmi

        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 1.94vmin;
        line-height: 2.78vmin;
        color: #636060;
        margin-bottom: 10px;


        &:active{
            background: #F9F9F9!important;
            color: #46747E!important;

            /* Neutral/4 */
            font-weight: 600;
            border: 0.11vmin solid #E4E3E3!important;
            box-shadow: 0px 0.14vmin 0.28vmin rgba(0, 0, 0, 0.15);

        }
        &:hover{
            color: #636060;
            border: none!important;

            background: #D2DDDE;
            box-shadow: 0px 0.14vmin 0.28vmin rgba(0, 0, 0, 0.15);

        }
        &:focus{
            border: 0.11vmin solid #637D83!important;
            background-color:transparent;
            color: #636060;

            box-shadow:none!important;
        }
        i{
            font-size:2.78vmin;
        }
    }
    button.active{
        background: #FFFFFF!important;
        border: 0.14vmin solid #E8E8E8!important;
        box-sizing: border-box;
        font-weight: 600;
        color: #0C8292!important;
    }
    
    img{
        height:21.11vmin;
    }
`