import React, { useContext } from "react";
import { Pagination } from "react-bootstrap";
import { MainContext } from "../context/MainContext";

export const PaginationButtons = () => {
  const {
    pagingInfo, currentPage, setCurrentPage
  } = useContext(MainContext);

  if (pagingInfo.totalPages > 1) {
    let items = [];
    items.push(
      <Pagination.Item
        key={"first"}
        disabled={currentPage === 1}
        onClick={() => setCurrentPage(1)}
      >
        <i className="bi bi-chevron-double-left" />
      </Pagination.Item>
    );
    items.push(
      <Pagination.Item
        key={"previous"}
        disabled={currentPage === 1}
        onClick={() => setCurrentPage(currentPage - 1)}
      >
        <i className="bi bi-chevron-left" />
      </Pagination.Item>
    );
    if(pagingInfo.totalPages > 7){
      if(currentPage + 3 >= pagingInfo.totalPages){
        for (let number = pagingInfo.totalPages -6; number <= pagingInfo.totalPages; number++) {
          items.push(
            <Pagination.Item
              key={number}
              active={currentPage == number}
              onClick={() => setCurrentPage(number)}
            >
              {number}
            </Pagination.Item>
          );
        }
      }else if(currentPage - 3 <= 0 ){
        for (let number = 1; number <= 5; number++) {
          items.push(
            <Pagination.Item
              key={number}
              active={currentPage == number}
              onClick={() => setCurrentPage(number)}
            >
              {number}
            </Pagination.Item>
          );
        }
        items.push(
          <Pagination.Item
            key={"..."}
          >
            ...
          </Pagination.Item>
        );
        items.push(
          <Pagination.Item
            key={pagingInfo.totalPages}
            active={currentPage == pagingInfo.totalPages}
            onClick={() => setCurrentPage(pagingInfo.totalPages)}
          >
          {pagingInfo.totalPages}
        </Pagination.Item>
        );
      }else {
        for (let number = currentPage-3 ; number <= currentPage+1; number++) {
          items.push(
            <Pagination.Item
              key={number}
              active={currentPage == number}
              onClick={() => setCurrentPage(number)}
            >
              {number}
            </Pagination.Item>
          );
        }
        items.push(
          <Pagination.Item
            key={"..."}
          >
            ...
          </Pagination.Item>
        );
        items.push(
          <Pagination.Item
            key={pagingInfo.totalPages}
            active={currentPage == pagingInfo.totalPages}
            onClick={() => setCurrentPage(pagingInfo.totalPages)}
          >
          {pagingInfo.totalPages}
        </Pagination.Item>
        );
      }

    }else {
      for (let number = 1; number <= pagingInfo.totalPages; number++) {
        items.push(
          <Pagination.Item
            key={number}
            active={currentPage == number}
            onClick={() => setCurrentPage(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
    }
    items.push(
      <Pagination.Item
        key={"next"}
        disabled={currentPage === pagingInfo.totalPages}
        onClick={() => setCurrentPage(currentPage + 1)}
      >
        <i className="bi bi-chevron-right" />
      </Pagination.Item>
    );
    items.push(
      <Pagination.Item
        key={"last"}
        disabled={currentPage === pagingInfo.totalPages}
        onClick={() => setCurrentPage(pagingInfo.totalPages)}
      >
        <i className="bi bi-chevron-double-right" />
      </Pagination.Item>
    );
    return <Pagination>{items}</Pagination>;
  } else {
    return <></>;
  }
};
