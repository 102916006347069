import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { PrimaryGreenButton } from "../../components/Button/styles";
import DatePicker from 'react-datepicker';
import { MainContext } from "../../context/MainContext";
import styled from "styled-components";





export function ReportFilter() {
  const {
    onChangeDatePickerChange, startDate, endDate, isValidReport, createReport

  } = useContext(MainContext);

  return (
  <ReportFilterStyles className="mt-5 me-5">
    <Col md='auto' className='my-auto '>
      <span>
        Histórico de Relatórios Gerados
      </span>
    </Col>
    <Col  md='auto' className='ms-auto my-auto '>
      <i 
        className="bi bi-calendar4-range"
      />
    </Col>
    <Col md='auto' className='my-auto '>
      <DatePicker
        //selected={startDate}
        showIcon
        onChange={onChangeDatePickerChange}
        startDate={startDate}
        endDate={endDate}
        dateFormat="dd/MM/yyyy"
        selectsRange
        />
    </Col>
    <Col md='auto' className='my-auto '>
      <PrimaryGreenButton disabled={!isValidReport()} className="px-4" onClick={() => { createReport(); }}>
        <Row>
          <Col>
            Gerar Relatório
          </Col>
        </Row>
      </PrimaryGreenButton>
    </Col>
  </ReportFilterStyles>
  )
}

const ReportFilterStyles = styled(Row)`
  .react-datepicker-wrapper{
    input{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 1.7vmin;

      background: #FFFFFF;
      border: 1px solid #E4E3E3;
      border-radius: 0.6vmin;

      font-family: 'Segoe UI';
      font-style: normal;
      font-weight: 400;
      font-size: 2vmin;      
      color: #656565;

    }
  }
  button{
    height:6.5vh;
    weight:100%;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 2.22vmin;
    line-height: 2.91vmin;
    i{
      font-size: 2.5vmin;

    }
  }
  i{
    font-size: 3vmin;

  }
  span{
        font-family: Lato;
        font-style: normal;
        font-weight: 400;
        font-size: 2.22vmin;
        line-height: 3.33vmin;
        background-color:transparent;
        color: #514F4E!important;

    }
`
