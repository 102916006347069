import React, { SyntheticEvent, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Form from "react-bootstrap/Form";
import { MainContext } from "../../context/MainContext";
import { PrimaryGreenButton } from "../Button/styles";

export const SearchBarComponent: React.FC = () => {

  const {
    clearSearch, searchData, pagingInfo, isLoadingList, isFilterWordMode, searchInputRef
  } = useContext(MainContext);

  function returnClassName(): string {
    if (isFilterWordMode && !isLoadingList) {
      if (pagingInfo.totalRecords > 0) {
        return "found";
      } else {
        return "not-found";
      }
    }

    return "";
  }

  return (
    <SearchBarComponentStyles className={returnClassName()}>
      <Row>
        <Col md={11}>
          <i className="search-icon bi bi-search"></i>
          <Form.Control
            aria-describedby="searchBarInput"
            placeholder={"Buscar por disciplina, professor(a) ou aula"}
            onKeyPress={(e:any) => {e.key==="Enter"&&(searchData()) }}
            ref={searchInputRef} />
          {isFilterWordMode && !isLoadingList && (
            <Form.Text id="searchBarInputResult" muted>
              {(pagingInfo.totalRecords === 0) && <>Nenhum resultado foi encontrado</>}
              {(pagingInfo.totalRecords === 1) && <>1 resultado foi encontrado</>}
              {(pagingInfo.totalRecords > 1) && <>{pagingInfo.totalRecords} resultados foram encontrados</>}
            </Form.Text>
          )}
          {isFilterWordMode &&
            <i className="bi bi-x" onClick={() => clearSearch()}></i>}
        </Col>
        <Col md={1}>
          <PrimaryGreenButton onClick={() => searchData()}>
            <Row>
              <Col>
                Buscar
              </Col>
            </Row>
          </PrimaryGreenButton>
        </Col>
      </Row>
    </SearchBarComponentStyles>
  );
};

const SearchBarComponentStyles = styled(Col)`
  height:11vh;
  margin-top: 2.8vmin;
  .search-icon{
    color:#656565;
    margin-top:0.5vmin;
    margin-bottom:auto;
    margin-left:1vmin;
    position:absolute;
    font-size:3.33vmin;
  }
  .bi-x{
    color:#656565;
    position:absolute;
    left:89%;
    font-size:4.4vmin;
    top:2.6vmin;
    cursor:pointer;

  }
  small{
    margin-top:0.7vh;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.667vmin;
    line-height: 2.778vmin;
    /* identical to box height, or 167% */

    letter-spacing: 0.2px;

    /* Interface/Primary/Medium */

    color: #46747E!important;
  }
  input{
    padding-left:6vmin;
    background: #FFFFFF;
    /* Neutral/4 */
    height: 6.66vmin;
    border: 0.14vmin solid #E4E3E3;
    border-radius: 0.56vmin;

    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 2.22vmin;
    line-height: 2.91vmin;
    color: #656565;

    &:focus{
      box-shadow:none;
      border: 0.14vmin solid #b4b4b4;

    }
    &::placeholder{
      font-family: 'Segoe UI';
      font-style: normal;
      font-weight: 400;
      font-size: 2.22vmin;
      line-height: 2.91vmin;

      color: #656565;
    }
  }
  &.found{
    input{
      border: 0.14vmin solid #46747E;
    }
    .bi-x{
      color:#46747E!important;

    }
  }
  &.not-found{
    input{
      border: 0.14vmin solid #D43B26;
    }
    small{
      color:#D43B26!important;
    }
    .bi-x{
      color:#D43B26!important;

    }
  }
  button{
    height:6.5vh;
    weight:100%;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 2.22vmin;
    line-height: 2.91vmin;

    /* Neutral/1 */

    color: #FFFFFF;
  }
  `;
