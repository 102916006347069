import React, { useContext, useEffect } from "react";
import { Col } from "react-bootstrap";
import CardFootstep from "../../../components/Cards/CardFootstep/CardFootstep";
import CardToday from "../../../components/Cards/CardToday/CardToday";
import { MainContext } from "../../../context/MainContext";
import { PaginationLayout } from "../../PaginationLayout";
import { PastClassSubtitle } from "../../PastClasses/PastClassSubtitle";

const ClassesThatAreFinishedListComponent: React.FC = ({}) => {
  const {
    classes, 
    currentPage, 
    loadClassesThatAreFinished,
    getSearchInputTextValue} = useContext(MainContext)

  useEffect(() => {
    let isFiltering:boolean = getSearchInputTextValue()!==""
    loadClassesThatAreFinished(currentPage,isFiltering)
  }, [currentPage]);

  return (
    <PaginationLayout
      reload={loadClassesThatAreFinished}
      subtitle={
        <PastClassSubtitle
          classes={classes}
        />
      }>
      {classes.map((item: any) => (
        <Col md={"auto"} className="pe-0">
          <CardFootstep
            countStudents={item.countStudents}
            teacherParticipation={item.teacherParticipation}
            date={item.date}
            descrpiton={item.description}
            startTime={item.startTime}
            endTime={item.endTime}
            name={item.name}
            teachers={item.teachers}
            punctualityClassStatus={item.punctualityClassStatus}
            finalizationClassStatus={item.finalizationClassStatus}
          />
        </Col>
      ))}
    </PaginationLayout>
  );
};
export default ClassesThatAreFinishedListComponent;
